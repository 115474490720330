<template>
    <span class="car-itinerary-notice" v-if="hasNotice">
        <font-awesome-icon :class="{'read': read, 'fa-lg notice-icon': true}" :icon="['far', 'envelope']"
                           @click="visible=true"></font-awesome-icon>
        <div :id="id" v-if="visible">
            <Alert v-model="visible"
                   :id="id"
                   title="Internal notice"
                   footer-class="car-itinerary-notice-footer"
                   @hide="hide">
                <slot>
                    {{ carItineraryHelper.getNotice() }}
                </slot>
            </Alert>
        </div>
    </span>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import QStepApi from '@/libraries/Api/QStep/Api'
import Alert from '@/components/Core/Dialog/Alert'
import md5 from 'js-md5'

export default {
  extends: AccessType,
  components: {
    Alert
  },
  props: {
    carItineraryHelper: {
      type: Object,
      required: true
    }
  },
  name: 'Notice',
  data () {
    return {
      hasNotice: this.carItineraryHelper.getNotice() !== '' && this.carItineraryHelper.getNotice() !== null,
      read: this.carItineraryHelper.isNoticeRead(this.accessType),
      showDetailsFailed: false,
      visible: false,
      id: 'car-itinerary-notice-' + this.carItineraryHelper.getCarItinerary().id,
      shcs: []
    }
  },
  methods: {
    hide () {
      this.$showLoadingOverlay()
      const _self = this
      const qStepApi = new QStepApi()

      let noticeReadPromise = null
      const messageSignature = md5(this.carItineraryHelper.getCarItinerary().car.notice)
      if (this.isPrivate()) {
        noticeReadPromise = qStepApi.setNoticeRead(this.carItineraryHelper.getCarItinerary().id, messageSignature)
      } else if (this.isPublic()) {
        noticeReadPromise = qStepApi.setNoticeRead(this.carItineraryHelper.getCarItinerary().id, messageSignature, this.encryptedData)
      }

      noticeReadPromise.then(response => {
        this.$hideLoadingOverlay()
        _self.$emit('hidden')
        _self.visible = false
        _self.read = true
      }).catch(() => {
        this.$hideLoadingOverlay()
        _self.$emit('hidden')
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.car-itinerary-notice {
  display: inline-block;

  .read {
    color: #C7C9CC;
  }

  .modal-body {
    text-align: left;
  }

  .car-itinerary-notice-footer {
    .btn-primary {
      display: none;
    }
  }

}
</style>
