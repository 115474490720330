<template>
  <LayoutLoggedIn :doneRendering="doneRendering" :accessType="accessType" module="qdrive">
    <v-layout row class="mt-2 mb-4">
      <v-btn class="back-button ml-4" @click="goBack" color="primary">Back</v-btn>
    </v-layout>
    <div class="compartment-seals">
      <h2 class="text-left">Compartments Seals</h2>
      <div class="data mt-2">
        <template>
          <v-dialog
              v-model="save.dialog"
              persistent
              max-width="480"
          >
            <v-card>
              <v-card-title class="headline">
                Add seal
              </v-card-title>
              <v-card-text>
                <v-text-field
                    class="compartment-add-seal-input"
                    label="Code"
                    v-model="save.value"
                    :rules="rules"
                    hide-details="auto"
                    :error-messages="error.duplicate"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error darken-1"
                    text
                    @click="save.dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                    class="compartment-add-seal-input-save"
                    color="green darken-1"
                    text
                    @click="addSeal"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template>
          <div v-for="(truckCode, index) in truckList" :key="index">
            <v-card class="mx-auto">
              <v-toolbar color="sucess">
                <v-icon
                    medium
                    color=" darken-2"
                >
                  mdi-{{ index === 0 ? 'truck' : 'truck-trailer' }}
                </v-icon>
                <v-toolbar-title>{{ truckCode }} Seals</v-toolbar-title>
                <v-spacer></v-spacer>
                <template v-if="compartments[truckCode]">
                  <v-btn
                      v-if="!hideBreakEmptyButton(compartments[truckCode])"
                      color="primary"
                      dark
                      :class="'compartment-seals-add-' + truckCode"
                      :disabled="addButtonState(truckCode)"
                      @click="()=>{ selectedCompartmentID = compartments[truckCode].id, save.dialog = true; } "
                  >
                    ADD
                  </v-btn>

                  <v-btn
                      v-if="!hideBreakEmptyButton(compartments[truckCode])"
                      color="success"
                      class="ml-5"
                      :class="'compartment-confirm-' + truckCode"
                      @click="confirmCompartment(compartments[truckCode],truckCode)"
                      :disabled="hideButton(compartments[truckCode])"
                  >SEAL
                    {{ index === 0 ? 'truck' : 'trailer' }}
                  </v-btn>
                  <v-btn
                      v-if="hideBreakEmptyButton(compartments[truckCode])"
                      color="error"
                      class="ml-5"
                      :class="'compartment-confirm-empty-' + truckCode"
                      @click="confirmCompartment(compartments[truckCode],truckCode)"
                  >Break Empty Compartment
                  </v-btn>
                </template>
              </v-toolbar>
              <div v-if="compartments[truckCode]">
                <v-list-item
                    v-for="(seal, i) in compartments[truckCode].seals" :key="i" class="mb-2"
                    v-bind:class="{  'lightGreen': seal.created_at && !compartments[truckCode].sealed_at && !seal.removed_at ,  'lightRed': seal.created_at && seal.removed_at, 'lightGrey' : seal.created_at && !seal.removed_at && seal.sealed_at && compartments[truckCode].sealed_at, 'newLightGreen': new Date(compartments[truckCode].sealed_at) < new Date(seal.created_at) && seal.removed_at < compartments[truckCode].sealed_at , 'newLightGreen' : !seal.removed_at  && seal.sealed_at > compartments[truckCode].sealed_at  }">
                  <v-list-item-icon>
                    <v-icon
                        class="mt-2"
                        v-if="seal.created_at && !seal.removed_at && !compartments[truckCode].sealed_at"
                    >
                      mdi-certificate-outline
                    </v-icon>
                    <v-icon
                        class="mt-2"
                        v-if="seal.created_at && !seal.removed_at && compartments[truckCode].sealed_at"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon
                        class="mt-2"
                        v-if="seal.created_at && seal.removed_at"
                    >
                      mdi-image-broken-variant
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="text-left">
                    <v-list-item-title v-text="seal.code"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                        v-if="!seal.removed_at"
                        :class="'compartment-seal-break-' + seal.code"
                        class="ml-2" color="error" @click=" ()=> breakSeal(seal, truckCode, i ) ">
                      Remove
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-card>
            <v-divider
                class="mx-4"
                vertical
            ></v-divider>
          </div>
        </template>
      </div>
    </div>
  </LayoutLoggedIn>

</template>

<script>
import AccessType from '@/components/Core/AccessType'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import QStepApi from '@/libraries/Api/QStep/Api'
import store from '@/store'
import CompartmentFilter from '@/libraries/Api/QStep/CarItineraryFilter/CompartmentFilter'

import Parameter from '@/libraries/Api/QStep/CarItineraryFilter/Parameter'

export default {
  extends: AccessType,
  components: {
    LayoutLoggedIn
  },
  name: 'List',
  data () {
    return {
      doneRendering: true,
      textFields: [],
      detailsVisible: false,
      carItineraries: [],
      compartments: [],
      sealButtonText: 'SEAL TRUCK',
      selectedCompartmentID: null,
      hideAdd: false,
      navigation: [
        {
          text: 'QDrive',
          disabled: false,
          href: '/qdrive/truck-number'
        },
        {
          text: 'Seals',
          disabled: true,
          href: '/qdrive/itinerary/seal'
        }
      ],
      error: {
        duplicate: null
      },
      save: {
        dialog: false,
        value: null
      },
      replace: {
        dialog: false,
        value: null,
        replace: null
      },
      unsignedCount: this.$store.getters.unsignedCount,
      truckList: this.$store.getters.truckList,
      seals: [],
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters'
      ],
      searching: false
    }
  },
  created () {
    this.LoadCompartment()
    this.searching = true
  },
  computed: {
    adminName () {
      return this.$store.getters.admin.name
    }
  },

  methods: {
    goBack: function () {
      if (this.isPrivate()) {
        this.$router.push('/qdrive/itinerary/loaded')
      } else {
        this.$router.replace({ name: 'EncryptedCarItinerary' })
      }
    },
    addButtonState (code) {
      let hide = false

      if (this.compartments[code]) {
        hide = !this.compartments[code].seals.filter(item => item.removed_at).length
      }

      if (this.compartments[code] && hide) {
        hide = !!this.compartments[code].seals.filter(item => !item.removed_at).length
      }

      if (this.compartments[code] && !this.compartments[code].removed_at && !this.hideButton(this.compartments[code])) {
        hide = false
      }

      return hide
    },
    hideBreakEmptyButton (compartment) {
      if (compartment) {
        if (compartment.sealed_at && compartment.seals.length === 0) {
          return true
        }
      }
      return false
    },
    hideButton (compartment) {
      if (compartment) {
        let hide
        if (compartment.sealed_at !== null) {
          hide = !compartment.seals.filter(item => item.removed_at).length
        }
        if (compartment.sealed_at !== null && hide) {
          hide = !compartment.seals.filter(item => item.sealed_at > compartment.sealed_at).length
        }
        return hide
      } else {
        return true
      }
    },
    addSeal () {
      this.error.duplicate = null
      this.$showLoadingOverlay()
      let addSealPromise = null
      const qStepApi = new QStepApi()
      if (this.isPrivate()) {
        addSealPromise = qStepApi.addSeal(this.save.value, this.selectedCompartmentID)
      } else if (this.isPublic()) {
        addSealPromise = qStepApi.addSeal(this.save.value, this.selectedCompartmentID, this.encryptedData)
      }

      addSealPromise.then(response => {
        this.$hideLoadingOverlay()
        this.LoadCompartment()
        this.save.value = ''
        this.save.dialog = false
      }).catch((error) => {
        if (error.response.data.message === 'ERROR') {
          this.save.value = ''
          this.error.duplicate = 'ERROR'
        }
        this.$hideLoadingOverlay()
      })
    },
    breakSeal (item, code, i) {
      this.error.duplicate = null
      this.hideAdd = true
      this.$showLoadingOverlay()
      let breakSealPromise = null
      const qStepApi = new QStepApi()
      if (this.isPrivate()) {
        breakSealPromise = qStepApi.breakSeal(item)
      } else if (this.isPublic()) {
        breakSealPromise = qStepApi.breakSeal(item, this.encryptedData)
      }

      breakSealPromise.then(response => {
        this.$hideLoadingOverlay()
        const tempSeal = this.compartments[code].seals
        this.$set(tempSeal, i, response.data.data)
        this.save.value = ''
        this.save.dialog = false
        this.LoadCompartment()
      }).catch((error) => {
        if (error.response.data.message === 'ERROR') {
          this.save.value = ''
        }
        this.$hideLoadingOverlay()
      })
    },
    confirmCompartment (item, truckNumber) {
      this.error.duplicate = null
      this.hideAdd = false
      this.$showLoadingOverlay()
      let confirmCompartmentPromise = null
      const qStepApi = new QStepApi()
      if (this.isPrivate()) {
        confirmCompartmentPromise = qStepApi.confirmCompartment(item, truckNumber)
      } else if (this.isPublic()) {
        confirmCompartmentPromise = qStepApi.confirmCompartment(item, truckNumber, this.encryptedData)
      }

      confirmCompartmentPromise.then(response => {
        this.$hideLoadingOverlay()
        this.LoadCompartment()
      }).catch((error) => {
        if (error.response.data.message === 'DUPLICATE_FOUND') {
          this.replace.replace = ''
        }
        this.$hideLoadingOverlay()
      })
    },
    remove (index) {
      this.textFields.splice(index, 1)
    },
    LoadCompartment () {
      let sealsPromise = null
      const qStepApi = new QStepApi()
      const compartmentFilter = new CompartmentFilter()
      compartmentFilter.addParameter(new Parameter('truckNumber', this.truckList))
      if (this.isPrivate()) {
        sealsPromise = qStepApi.getSeals(compartmentFilter)
      } else if (this.isPublic()) {
        sealsPromise = qStepApi.getSeals(compartmentFilter, this.encryptedData)
      }

      sealsPromise.then(response => {
        console.log('checking')
        if (this.isPublic()) {
          const truckList = response.data.data.compartments.map(item => {
            return item.truckNumber
          })
          store.commit('truckList', truckList)
        }
        this.compartments = this.convertTruckBasedRecord(response.data.data)
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    toggleFilter () {
      this.filterForm.open = !this.filterForm.open
    },
    convertTruckBasedRecord (list) {
      const newList = {}
      for (const compartment of list.compartments) {
        if (typeof compartment.truckNumber === 'string') {
          this.$set(newList, compartment.truckNumber, compartment)
        }
      }
      return newList
    },
    updateOptions (searchQuery) {
      if (this.isPrivate()) {
        const qStepApi = new QStepApi()
        const _self = this

        const cancelSource = qStepApi.cancelToken()

        qStepApi.searchIata(searchQuery, cancelSource, this.cancelSource).then(response => {
          if (response.data.data !== undefined) {
            _self.filterForm.locations.options = response.data.data.map(data => {
              return data.code + ' - ' + data.city
            })
          }
        })

        this.cancelSource = cancelSource
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.compartment-seals {
  .lightGreen {
    background-color: rgb(50, 168, 82, 0.5);
  }

  .newLightGreen {
    background-color: rgb(50, 168, 82, 0.5) !important;
  }

  .lightRed {
    background-color: rgb(255, 0, 0, 0.5);
  }

  .lightGrey {
    background-color: rgb(105, 105, 105, 0.5);
  }
}

.car-itineraries {
  .send-rfs-email {
    text-align: right;
  }

  .filter {
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;

    .footer {
      vertical-align: middle;
    }

    .v-text-field__details {
      display: none;
    }
  }

  .data {
    .car-itineraries-not-found {
      margin-top: 15px;
    }
  }
}
</style>
