<template>
    <v-text-field :class="{'error-highlight': errorHighlight}" :id="id" v-model.number="value"
                  v-bind="$attrs" type="number"
                  @input="onInput" :readonly="readonly"
                  :label="label" outlined></v-text-field>
</template>

<script>

import InputField from '@/components/Core/Input/Core/Input'

export default {
  extends: InputField,
  name: 'NumberInput',
  props: {
    valueP: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    valueOnEmpty: {
      type: Number,
      default: null
    }
  },
  model: {
    prop: 'valueP',
    event: 'change'
  },
  data () {
    return {
      value: this.valueP
    }
  },
  methods: {
    onInput: function (value) {
      this.$emit('input', value)

      if (this.lodash.isEmpty(value)) {
        if (this.valueOnEmpty === 0) {
          value = this.valueOnEmpty
        } else {
          value = null
        }
      } else {
        value = parseInt(value)
      }

      this.value = value
    }
  },
  watch: {
    value: function (newValue) {
      this.$emit('change', newValue)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
