<template>
    <div class="signature-pad">
        <label :class="{'error-highlight': errorHighlight}">{{ labelRequired }}</label>
        <VueSignaturePad
                width="100%"
                height="200px"
                ref="signaturePad"
                :class="{'pad': true, 'error-highlight': errorHighlight}"
                :options="{ backgroundColor: '#FFFFFF', onEnd: _fireChange }"
        />

        <div class="action-bar">
            <v-alert type="info" class="ma-0 text-left" text v-if="signature.isEmpty">
                Draw signature into box above
            </v-alert>
            <template v-else>
                <v-btn @click="clear" outlined color="error" class="mr-4">Clear</v-btn>
                <v-btn @click="undo" outlined color="primary">Undo</v-btn>
            </template>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/Core/Input/Core/Input'

const SVG = 'image/svg+xml'
const PNG = 'image/png'
const JPEG = 'image/jpeg'

export default {
  SVG: SVG,
  PNG: PNG,
  JPEG: JPEG,
  extends: InputField,
  name: 'SignaturePad',
  props: {
    imageType: {
      type: String,
      default: SVG
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    signature: { isEmpty: true, data: undefined }
  }),
  methods: {
    isEmpty () {
      return this.$refs.signaturePad.isEmpty()
    },
    clear () {
      this.$refs.signaturePad.clearSignature()
      this._fireChange()
    },
    undo () {
      this.$refs.signaturePad.undoSignature()
      this._fireChange()
    },
    getDataUrl () {
      if (this.hasImage()) {
        const image = this.$refs.signaturePad.saveSignature(this.imageType)
        return image.data
      }

      return null
    },
    hasImage () {
      const image = this.$refs.signaturePad.saveSignature()
      return !image.isEmpty
    },
    getBase64Encoding () {
      if (this.hasImage()) {
        return this.lodash.replace(this.getDataUrl(), 'data:' + this.imageType + ';base64,', '')
      }

      return this.getDataUrl()
    },
    getExtension () {
      if (this.imageType === SVG) {
        return 'svg'
      } else {
        if (this.imageType === PNG) {
          return 'png'
        } else {
          if (this.imageType === JPEG) {
            return 'jpg'
          }
        }
      }
    },
    _fireChange () {
      this.signature = this.$refs.signaturePad.saveSignature(this.imageType)
      this.$emit('change', this.signature)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .signature-pad {
        label {
            margin-left: 10px;
            display: block;
            color: rgba(0, 0, 0, .54);

            &.error-highlight {
                color: #ff5252;
            }
        }

        .pad {
            margin-bottom: 10px;

            &.error-highlight {
                border-color: #ff5252;
            }
        }

        .action-bar {
            text-align: right;
        }
    }
</style>
