<template>
   <div class="select-input">
       <v-select
               :id="id"
               :taggable="taggable"
               :multiple="multiple"
               :filter="filter"
               v-model="values"
               v-bind="$attrs"
               :options="optionsD">
           <template v-slot:no-options>Type to select</template>
       </v-select>
       <div class="label-wrapper">
           <label>{{  label }}</label>
       </div>
   </div>
</template>

<script>

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect
  },
  name: 'SelectInput',
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    label: {
      type: String,
      default: '',
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    valuesP: {
      type: Array,
      default: function () { return [] }
    },
    options: {
      type: Array,
      default: function () { return [] }
    }
  },
  mounted: function () {
    const _self = this
    const element = document.querySelectorAll('#' + this.id + ' .vs__search')
    for (let i = 0; i < element.length; i++) {
      element[i].addEventListener('input', function (e) {
        _self.$emit('input', element[i].value)
      })
    }
  },
  model: {
    prop: 'valuesP',
    event: 'change'
  },
  data () {
    return {
      optionsD: this.options,
      valuesD: this.valuesP
    }
  },
  computed: {
    values: {
      get: function () {
        return this.valuesD
      },
      set: function (values) {
        const valuesD = []
        const _self = this
        values.forEach(function (value) {
          let valueD = value
          if (_self.uppercase) {
            valueD = valueD.toUpperCase()
          }

          if (_self.maxLength > 0) {
            valueD = valueD.slice(0, _self.maxLength)
          }

          valuesD.push(valueD)
        })

        this.valuesD = valuesD
      }
    }
  },
  methods: {
    filter (options) {
      return options
    }
  },
  watch: {
    valuesD: function (newValues) {
      this.$emit('change', newValues)
    },
    options: function (newValues) {
      this.optionsD = newValues
    }
  }
}

// export default Select
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .select-input {
        .label-wrapper {
            display: block;
            text-align: left;

            position: relative;
            top: -68px;
            left: 10px;

            label {
                position: relative;
                z-index: 1;
                background-color: #FFFFFF;
                color: rgba(0, 0, 0, .54);
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        .v-select {
            .vs__dropdown-toggle {
                height: 56px;
            }

            .vs__selected {
                height: 35px;
                margin-top: 15px;
            }
        }
    }
</style>
