<template>
   <div class="message loading">
       <v-progress-linear
               indeterminate
               color="blue"
       ></v-progress-linear>
       <v-alert show type="info">
           <slot>Loading message, please fill in the slot</slot>
       </v-alert>
   </div>
</template>

<script>

export default {
  name: 'Loading'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .message.loading {
        .v-alert {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .v-progress-linear {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
</style>
