<template>
    <layout-logged-in :doneRendering="doneRendering" :accessType="accessType" :showBackButton="true">
        <a v-if="isPublic()" class="btn btn-primary download-cmr" :download="'cmr-' + cmrNumber + '.pdf'" :href="'data:application/pdf;base64,' + src" target="_blank">Download</a>
        <a v-if="isPublic()" class="btn btn-primary print" href="#" @click="printPdf">Print</a>
        <span class="cmr">
             <pdf ref="pdf" v-for="i in pages"
                  :key="i"
                  :page="i"
                  style="display: inline-block; width: 100%"
                  :src="'data:application/pdf;base64,' + src"></pdf>
        </span>
    </layout-logged-in>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import QStepApi from '@/libraries/Api/QStep/Api'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import PrintJs from 'print-js'
import Pdf from 'vue-pdf'

export default {
  extends: AccessType,
  components: {
    Pdf, LayoutLoggedIn
  },
  props: {
    carItineraryId: {
      type: Number,
      required: true
    }
  },
  name: 'Cmr',
  data () {
    return {
      doneRendering: false,
      pages: 0,
      src: '',
      cmrNumber: this.carItineraryId
    }
  },
  mounted () {
    this.loadCmr()
  },
  methods: {
    printPdf (e) {
      PrintJs({ printable: this.src, type: 'pdf', base64: true })
    },
    loadCmr () {
      this.$showLoadingOverlay()
      const _self = this

      const qStepApi = new QStepApi()

      let cmrPromise = null

      if (this.isPrivate()) {
        cmrPromise = qStepApi.getCmr(this.carItineraryId)
      } else if (this.isPublic()) {
        cmrPromise = qStepApi.getCmr(this.carItineraryId, this.encryptedData)
      }
      cmrPromise.then(response => {
        if (response.data.data !== undefined) {
          _self.src = response.data.data.pdfStream
          _self.pages = response.data.data.pagesCount
          _self.cmrNumber = response.data.data.cmrNumber
        }

        this.$hideLoadingOverlay()
        _self.doneRendering = true
      }).catch(() => {
        this.$hideLoadingOverlay()
        _self.doneRendering = true
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .print {
        margin-left: 20px;
    }

    @media print {
        .flex.header, .download-cmr {
            display: none;
        }
    }
</style>
