<template>
   <div class="core-modal-dialog modal-alert-dialog">
       <v-dialog v-model="show"
                :id="id"
                 @click:outside="close"
                max-width="500">
           <v-card>
               <v-card-title
                       class="headline white lighten-2"
                       primary-title>
                   <slot name="modal-title">{{ title }}</slot>
               </v-card-title>
               <div class="v-divider theme--light"></div>
               <v-card-text class="pt-5">
                   <slot></slot>
               </v-card-text>

               <v-divider></v-divider>

               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn
                           color="primary"
                           text
                           @click="close">
                       {{ hideButtonText }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<script>

export default {
  name: 'Alert',
  props: {
    id: {
      type: String,
      default: 'modal-alert-dialog',
      required: true
    },
    showP: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Alert'
    },
    hideButtonText: {
      type: String,
      default: 'Close'
    }
  },
  model: {
    prop: 'showP',
    event: 'visibility'
  },
  data () {
    return {
      show: this.showP
    }
  },
  methods: {
    updateVisibility (val) {
      this.show = val
      this.$emit('visibility', val)
    },
    close () {
      this.updateVisibility(false)
      this.$emit('hide')
      this.$emit('hidden')
    }
  },
  watch: {
    showP: function (newVal) {
      this.updateVisibility(newVal)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .core-modal-dialog {
        .v-card__text {
            text-align: left;
        }
    }
</style>
