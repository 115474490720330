<template>
  <ErrorBase>
    <template v-slot:errorMessage>412 - Invalid data format!</template>
  </ErrorBase>
</template>

<script>

import ErrorBase from './ErrorBase'

export default {
  components: {
    ErrorBase
  },
  name: 'Error412'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
