<template>
    <LayoutMain :hideScrollBar="hideScrollBar" class="logged-in-empty">
        <template v-slot:header><span></span></template>
        <slot></slot>
    </LayoutMain>
</template>

<script>

import LoggedIn from '@/components/Layout/LoggedIn'
import LayoutMain from '@/components/Layout/Main'

export default {
  components: {
    LayoutMain
  },
  extends: LoggedIn,
  name: 'LoggedInEmpty',
  props: {
    showBackButton: {
      type: Boolean,
      default: false
    },
    hideScrollBar: {
      type: Boolean,
      default: false
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .logged-in-empty {
        .container.page {
            padding-top: 0px;
        }
    }
</style>
