<template>
  <layout-logged-in :accessType="accessType" :doneRendering="doneRendering" :showBackButton="true">
    <div :id="id" class="car-itinerary-details">
      <AlertDialog :id="'car-shipment-details-updated-' + carItineraryId"
                   v-model="visibleCarShipmentDetailsUpdateSuccess">Successfully
        updated car shipment details
      </AlertDialog>

      <AlertDialog title="Error" :id="'car-shipment-details-update-failed-' + carItineraryId"
                   v-model="visibleCarShipmentDetailsUpdateFailure">Failed
        to update shipment details. Could be a technical issue, please try again later.
      </AlertDialog>

      <h2>Itinerary details: {{ carItineraryHelper.getTitle() }}</h2>
      <v-stepper
          v-model="state"
          vertical
          v-if="state < 6"
      >
        <v-stepper-step
            :complete="state > 1"
            step="1"
        >
          Loading
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-alert
              v-if="!allowLoading"
              class="mr-2 mt-5 d-flex justify-center col-33"
              type="error"
          >First break the seal to load the shipment
          </v-alert>
          <v-btn
              :disabled="!allowLoading"
              color="primary"
              class="startLoading"
              @click="startLoading"
          >
            Start Loading
          </v-btn>
        </v-stepper-content>
        <v-stepper-step
            :complete="state > 2"
            step="2"
        >
          Signing
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-btn
              color="primary"
              @click="redirectToItinerary"
          >
            Take Consignor Signature
          </v-btn>
          {{ ' ' }}
          <v-btn :disabled="!allowLoadingUndo" @click="openDialogLoading">
            Cancel Loading
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
            :complete="state > 3"
            step="3"
        >
          Loading Completed
        </v-stepper-step>

        <v-stepper-content step="3">
        </v-stepper-content>

        <v-stepper-step step="4" :complete="state > 4">
          Unloading
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-alert
              v-if="!allowLoading"
              class="mr-2 mt-5 d-flex justify-center col-33"
              type="error"
          >First break the seal to unload the shipment
          </v-alert>
          <v-btn
              :disabled="!allowLoading"
              color="primary"
              @click="startUnLoading"
          >
            Start unLoading
          </v-btn>
          {{ ' ' }}
          <v-btn :disabled="!allowLoadingUndo || disableSkip()" @click="openDialogLoading">
            Cancel Loading
          </v-btn>
        </v-stepper-content>
        <v-stepper-step step="5">
          Signing
        </v-stepper-step>
        <v-stepper-content step="5" :complete="state > 5">
          <v-btn
              color="primary"
              @click="SignDelivery"
          >
            Take Consignee Signature
          </v-btn>
          {{ ' ' }}
          <v-btn @click="openDialogUnLoading">
            Cancel Unloading
          </v-btn>
        </v-stepper-content>
        <v-stepper-step step="6">
          Unloading Completed
        </v-stepper-step>
      </v-stepper>

      <v-stepper
          v-model="state"
          vertical
          class="pb-2 mt-10"
          v-if="state > 5"
      >
        <v-stepper-step
            :complete="state > 5"
            step="6"
        >
          Loading Completed
        </v-stepper-step>
        <v-stepper-step
            :complete="state > 5"
            step="6"
        >
          Unloading Completed
        </v-stepper-step>
      </v-stepper>

      <v-dialog
          v-model="dialogLoading"
          persistent
          max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Cancel Loading?
          </v-card-title>
          <v-card-text>Set itinerary as not unloaded, are you sure?.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error darken-1"
                text
                @click="cancel"
            >
              Disagree
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="CancelLoading"
            >
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogUnLoading"
          persistent
          max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Cancel Unloading?
          </v-card-title>
          <v-card-text>Set itinerary as not unloaded, are you sure?.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error darken-1"
                text
                @click="cancel"
            >
              Disagree
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="CancelUnLoading"
            >
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <br/>
      <v-container class="content">
        <v-layout>
          <v-flex class="md12">
            <span class="name">Loading address:</span> {{ details.pickUpAddress }}
          </v-flex>
        </v-layout>
        <v-layout class="mt-2">
          <v-flex class="md12">
            <span class="name">Unloading address:</span> {{ details.dropOffAddress }}
          </v-flex>
        </v-layout>
      </v-container>

      <br/>
      <v-container class="content shipments">
        <v-layout class="header">
          <v-flex class="xs4 sm4 md4 data-cell awb">
            AWB
          </v-flex>
          <v-flex class="xs1 sm1 md1 data-cell pieces">
            Pcs
          </v-flex>
          <v-flex class="xs2 sm2 md2 data-cell weight">
            Act.wt
          </v-flex>
          <v-flex class="xs2 sm2 md2 data-cell volume">
            VOL
          </v-flex>
          <v-flex class="xs3 sm3 md3 data-cell shc">
            SHC
          </v-flex>
        </v-layout>

        <v-layout class="shipment" :key="index" v-for="(shipment, index) in details.shipments">
          <v-flex :class="{'xs4 sm4 md4 data-cell awb': true, 'shipment-edit': showShipmentDetailsEdit(shipment)}">
            {{ shipment.awb }}
          </v-flex>
          <v-flex :class="{'xs1 sm1 md1 data-cell pieces': true, 'data-cell-edit': showShipmentDetailsEdit(shipment)}">
            <EditShipmentNumberField :carItineraryHelper="carItineraryHelper"
                                     :shipment="shipment"
                                     :shipmentId="shipment.id"
                                     name="pieces"
                                     v-model.number="shipment.pieces"
                                     :textValue="shipment.pieces + ''"
                                     @input="onPiecesInput"></EditShipmentNumberField>
          </v-flex>
          <v-flex :class="{'xs2 sm2 md2 data-cell weight': true, 'data-cell-edit': showShipmentDetailsEdit(shipment)}">
            <EditShipmentNumberField :carItineraryHelper="carItineraryHelper"
                                     :shipment="shipment"
                                     :shipmentId="shipment.id"
                                     name="weight"
                                     :value="shipment.weight"
                                     :textValue="shipment.weight | oneDecimal | thousandFormat"
                                     @input="onWeightInput"
            ></EditShipmentNumberField>
          </v-flex>
          <v-flex :class="{'xs2 sm2 md2 data-cell volume': true, 'shipment-edit': showShipmentDetailsEdit(shipment)}">
            {{ shipment.volume | twoDecimal }}
          </v-flex>
          <v-flex :class="{'xs3 sm3 md3 data-cell shc': true, 'shipment-edit': showShipmentDetailsEdit(shipment)}">
            {{ shipment.shcs.join(' ') }}
          </v-flex>
        </v-layout>

        <v-layout class="totals">
          <v-flex class="xs4 sm4 md4 data-cell awb">
          </v-flex>
          <v-flex class="xs1 sm1 md1 data-cell pieces">
            {{ details.shipmentTotals.pieces }}
          </v-flex>
          <v-flex class="xs2 sm2 md2 data-cell weight">
            {{ details.shipmentTotals.weight | oneDecimal | thousandFormat }}
          </v-flex>
          <v-flex class="xs2 sm2 md2 data-cell volume">
            {{ details.shipmentTotals.volume | twoDecimal }}
          </v-flex>
          <v-flex class="xs3 sm3 md3 data-cell shc">

          </v-flex>
        </v-layout>

        <v-layout v-if="showDetailsEdit()">
          <v-flex class="action-bar">
            <v-btn color="primary" @click="save">Save</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <AlertDialog :id="'failed-details-' + carItineraryId" v-model="showDetailsFailed">Failed to load car itinerary
      details
    </AlertDialog>
  </layout-logged-in>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import moment from 'moment'
import QStepApi from '@/libraries/Api/QStep/Api'
import CarItineraryHelper from '@/libraries/Models/CarItinerary'
import CompartmentFilter from '@/libraries/Api/QStep/CarItineraryFilter/CompartmentFilter'
import Float from '@/libraries/Util/Float'
import AlertDialog from '@/components/Core/Dialog/Alert'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import EditShipmentNumberField from '@/components/CarItinerary/EditShipmentNumberField'
import QStepError from '@/libraries/Api/QStep/Response/Error'
import Parameter from '@/libraries/Api/QStep/CarItineraryFilter/Parameter'
import Utilities from '@/libraries/Util/Utilities'

export default {
  extends: AccessType,
  components: {
    AlertDialog, LayoutLoggedIn, EditShipmentNumberField
  },
  props: {
    carItineraryId: {
      type: Number,
      required: true
    }
  },
  name: 'Details',
  data () {
    return {
      doneRendering: false,
      showDetailsFailed: false,
      allowLoading: false,
      allowLoadingUndo: false,
      allowUnLoading: false,
      carItinerary: null,
      truckList: this.$store.getters.truckList,
      state: 1,
      dialogLoading: false,
      dialogUnLoading: false,
      id: 'car-itinerary-details-' + this.carItineraryId,
      details: {
        id: null,
        loadingCompletedAt: null,
        unloadingCompletedAt: null,
        pickUpAddress: '',
        pickUpDate: null,
        compartments: [],
        dropOffAddress: '',
        notice: '',
        shipments: [],
        shipmentTotals: {
          pieces: '',
          weight: '',
          volume: ''
        }
      },
      visibleCarShipmentDetailsUpdateSuccess: false,
      visibleCarShipmentDetailsUpdateFailure: false
    }
  },
  mounted () {
    this.updateDetails()
  },
  computed: {
    carItineraryHelper () {
      return new CarItineraryHelper(this.carItinerary)
    }
  },
  filters: {
    thousandFormat (number) {
      return Float.thousandFormat(number)
    },
    oneDecimal (number) {
      return Float.trimZeroes(parseFloat(number).toFixed(1))
    },
    twoDecimal (number) {
      return Float.trimZeroes(parseFloat(number).toFixed(2))
    }
  },
  methods: {
    disableSkip () {
      return moment().subtract(2, 'd').toDate() > moment(this.details.pickUpDate).toDate()
    },
    openDialogLoading () {
      this.dialogLoading = true
    },
    openDialogUnLoading () {
      this.dialogUnLoading = true
    },
    CancelUnLoading () {
      this.$showLoadingOverlay()
      const _self = this
      const qStepApi = new QStepApi()

      let deleteUnloadedPromise = null

      if (this.isPrivate()) {
        deleteUnloadedPromise = qStepApi.deleteUnloaded(this.carItineraryHelper.getCarItinerary().id)
      } else if (this.isPublic()) {
        deleteUnloadedPromise = qStepApi.deleteUnloaded(this.carItineraryHelper.getCarItinerary().id, this.encryptedData)
      }

      deleteUnloadedPromise
          .then(response => {
            this.updateDetails()
          })
          .catch(function (error) {
            const qStepError = new QStepError(error)
            if (qStepError.isCarItineraryUnloadedChangeForbidden()) {
              _self.showChangeForbiddenAlert()
              _self.$emit('changeForbidden', error)
            } else {
              _self.showErrorAlert()
              _self.$emit('error', error)
            }
            this.$hideLoadingOverlay()
          })
      this.dialogUnLoading = false
    },
    CancelLoading () {
      this.$showLoadingOverlay()
      const _self = this
      const qStepApi = new QStepApi()
      let cancelPickUpPromise = null
      if (this.isPrivate()) {
        cancelPickUpPromise = qStepApi.cancelPickUp(this.carItineraryHelper.getCarItinerary().id)
      } else if (this.isPublic()) {
        cancelPickUpPromise = qStepApi.cancelPickUp(this.carItineraryHelper.getCarItinerary().id, this.encryptedData)
      }
      cancelPickUpPromise.then(response => {
        this.updateDetails()
      })
          .catch(function (error) {
            const qStepError = new QStepError(error)
            if (qStepError.isCarItineraryPickUpdateChangeForbidden()) {
              _self.showChangeForbiddenAlert()
              _self.$emit('changeForbidden', error)
            } else {
              _self.showErrorAlert()
              _self.$emit('error', error)
            }
            this.$hideLoadingOverlay()
          })
      this.dialogLoading = false
    },
    cancel () {
      this.dialogLoading = false
      this.dialogUnLoading = false
    },
    stateSelector (state) {
      if (state === 'prepared' || state === 'initial') {
        return 1
      }
      if (state === 'loading') {
        return 2
      }
      if (state === 'loadingCompleted') {
        return 4
      }
      if (state === 'unLoading') {
        return 5
      }
      if (state === 'unLoadedCompleted') {
        return 6
      }
    },
    cancelAllowed (compartments) {
      let allowLoadingUndo = false
      compartments.map(compartment => {
        if (compartment.seals.length === 0) {
          allowLoadingUndo = true
          return
        }
        if (allowLoadingUndo) {
          return
        }
        compartment.seals.forEach(item => {
          if (item.removed_at != null) {
            allowLoadingUndo = true
            return
          }
          if (item.removed_at === null && compartment.sealed_at === null) {
            allowLoadingUndo = true
          }
        })
      })
      this.allowLoadingUndo = allowLoadingUndo
    },
    hasSeal (compartments) {
      this.allowLoading = Utilities.compartmentStatus(compartments);
    },
    startUnLoading () {
      this.$showLoadingOverlay()
      const _self = this
      const qStepApi = new QStepApi()

      let updateUnloadedPromise = null
      if (this.isPrivate()) {
        updateUnloadedPromise = qStepApi.updateUnloaded(this.carItineraryHelper.getCarItinerary().id)
      } else if (this.isPublic()) {
        updateUnloadedPromise = qStepApi.updateUnloaded(this.carItineraryHelper.getCarItinerary().id, this.encryptedData)
      }
      updateUnloadedPromise.then(response => {
        this.updateDetails()
      }).catch(function (error) {
        const qStepError = new QStepError(error)
        if (qStepError.isCarItineraryUnloadedChangeForbidden()) {
          _self.$emit('changeForbidden', error)
        } else {
          _self.showErrorAlert()
          _self.$emit('error', error)
        }
        this.$hideLoadingOverlay()
      })
      this.dialog = false
    },
    startLoading () {
      if (this.isPrivate()) {
        this.$router.push({
          name: 'CmrLiabilities',
          params: { carItineraryId: this.carItineraryHelper.getCarItinerary().id }
        })
      } else if (this.isPublic()) {
        this.$router.push({
          name: 'EncryptedCmrLiabilities',
          params: { carItineraryId: this.carItineraryHelper.getCarItinerary().id, encryptedData: this.encryptedData }
        })
      }
    },
    SignDelivery () {
      if (this.isPrivate()) {
        this.$router.push({
          name: 'SignDelivery',
          params: { carItineraryId: this.carItineraryHelper.getCarItinerary().id }
        })
      } else {
        this.$router.push({
          name: 'EncryptedSignDelivery',
          params: { carItineraryId: this.carItineraryHelper.getCarItinerary().id, encryptedData: this.encryptedData }
        })
      }
    },

    isSealBroken () {
      if (this.details.compartments.length > 0) {
        if (this.details.compartments[this.details.compartments.length - 1]) {
          return true
        }
      }
      return false
    },
    redirectToItinerary () {
      this.$router.push({ name: 'ConsignorSignature', params: { carItineraryId: this.details.id } })
    },
    showDetailsEdit () {
      return !this.carItineraryHelper.isPickedUp() && this.hasManuallyCreatedShipments()
    },

    showShipmentDetailsEdit (shipment) {
      return this.showDetailsEdit() && shipment.isCreatedManually
    },

    hasManuallyCreatedShipments () {
      for (let i = 0; i < this.details.shipments.length; i++) {
        if (this.details.shipments[i].isCreatedManually) {
          return true
        }
      }
      return false
    },
    onPiecesInput (input) {
      for (let i = 0; i < this.details.shipments.length; i++) {
        if (this.details.shipments[i].id === input.shipmentId) {
          this.details.shipments[i].newPieces = parseInt(input.value)
        }
      }
    },
    onWeightInput (input) {
      for (let i = 0; i < this.details.shipments.length; i++) {
        if (this.details.shipments[i].id === input.shipmentId) {
          this.details.shipments[i].newWeight = parseFloat(input.value)
        }
      }
    },
    save () {
      const _self = this
      if (this.hasManuallyCreatedShipments()) {
        const shipmentDetails = []
        for (let i = 0; i < this.details.shipments.length; i++) {
          const shipment = this.details.shipments[i]
          const shipmentDetail = {}

          if (!this.lodash.isUndefined(shipment.newPieces) && this.lodash.isNumber(shipment.newPieces)) {
            shipmentDetail.piecesActual = shipment.newPieces
            shipmentDetail.piecesManifested = shipment.newPieces
          }

          if (!this.lodash.isUndefined(shipment.newWeight) && this.lodash.isNumber(shipment.newWeight)) {
            shipmentDetail.weightActual = shipment.newWeight
            shipmentDetail.weightManifested = shipment.newWeight
          }

          if (!this.lodash.isEmpty(shipmentDetail)) {
            shipmentDetail.shipmentId = shipment.id
            shipmentDetails.push(shipmentDetail)
          }
        }

        this.$showLoadingOverlay()
        const qStepApi = new QStepApi()

        const detailsUpdatePromise = qStepApi.updateCarShipmentDetails(this.carItinerary.car.id, shipmentDetails)

        detailsUpdatePromise.then(response => {
          if (response.data.success) {
            _self.updateDetails()
            _self.showCarShipmentDetailsUpdateSuccess()
          } else {
            _self.showCarShipmentDetailsUpdateFailure()
            this.$hideLoadingOverlay()
          }
          this.$hideLoadingOverlay()
        }).catch(() => {
          _self.showCarShipmentDetailsUpdateFailure()
          this.$hideLoadingOverlay()
        })
      }
    },
    unloadingConfirmed () {
      this.updateDetails()
    },
    unloadingCancelled () {
      this.updateDetails()
    },
    hasNotice () {
      return this.details.notice !== null && this.details.notice !== ''
    },
    showCarShipmentDetailsUpdateSuccess () {
      this.visibleCarShipmentDetailsUpdateSuccess = true
    },
    showCarShipmentDetailsUpdateFailure () {
      this.visibleCarShipmentDetailsUpdateFailure = true
    },
    updateDetails () {
      this.$showLoadingOverlay()
      const _self = this
      const qStepApi = new QStepApi()
      let pickUpDetailsPromise = null
      let sealListPromise = null

      if (this.isPrivate()) {
        pickUpDetailsPromise = qStepApi.getPickUpDetails(this.carItineraryId)
      } else if (this.isPublic()) {
        pickUpDetailsPromise = qStepApi.getPickUpDetails(this.carItineraryId, this.encryptedData)
      }

      pickUpDetailsPromise
          .then(response => {
            if (response.data.data !== undefined) {
              const payload = response.data.data
              _self.carItinerary = payload
              this.state = this.stateSelector(payload.state)
              const compartmentFilter = new CompartmentFilter()
              compartmentFilter.addParameter(new Parameter('truckNumber', this.truckList))
              if (this.isPrivate()) {
                sealListPromise = qStepApi.getSeals(compartmentFilter)
              }
              if (this.isPublic()) {
                sealListPromise = qStepApi.getSeals(compartmentFilter, this.encryptedData)
              }

              sealListPromise
                  .then(sealResponse => {
                    this.hasSeal(sealResponse.data.data.compartments)
                    this.cancelAllowed(sealResponse.data.data.compartments)
                  })

              if (payload.departureClient !== undefined && payload.departureClient !== null) {
                _self.details.pickUpAddress = payload.departureClient.company + ' - ' + payload.departureClient.address + ', ' + payload.departureClient.zipCode + ' ' + payload.departureClient.city
              }

              if (payload.arrivalClient !== undefined && payload.arrivalClient !== null) {
                _self.details.dropOffAddress = payload.arrivalClient.company + ' - ' + payload.arrivalClient.address + ', ' + payload.arrivalClient.zipCode + ' ' + payload.arrivalClient.city
              }

              if (payload.car.notice !== undefined && payload.car.notice !== null) {
                _self.details.notice = payload.car.notice
              }

              _self.details.compartments = _self.carItinerary.compartments
              _self.details.loadingCompletedAt = _self.carItinerary.loadingCompletedAt
              _self.details.unLoadingCompletedAt = _self.carItinerary.unLoadingCompletedAt
              _self.details.pickUpDate = _self.carItinerary.pickUpDate
              _self.details.id = _self.carItinerary.id

              if (payload.car.shipments !== undefined && payload.car.shipments !== null) {
                _self.details.shipments = payload.car.shipments.map(shipment => {
                  return {
                    id: shipment.id,
                    isCreatedManually: shipment.isCreatedManually,
                    awb: shipment.masterAwb,
                    pieces: shipment.piecesManifested,
                    weight: shipment.weightManifested,
                    volume: shipment.volumeManifested,
                    shcs: shipment.shcs.map(shc => {
                      if (shc.showInQDrive) {
                        return shc.code
                      }
                    })
                  }
                })
              }

              if (payload.car.totals !== undefined && payload.car.totals !== null) {
                _self.details.shipmentTotals.pieces = payload.car.totals.piecesManifested
                _self.details.shipmentTotals.weight = payload.car.totals.weightManifested
                _self.details.shipmentTotals.volume = payload.car.totals.volumeManifested
              }
            }

            _self.doneRendering = true
            this.$hideLoadingOverlay()
          }).catch((e) => {
        console.log('error', e)
        this.showDetailsFailed = true
        _self.doneRendering = true
        this.$hideLoadingOverlay()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.car-itinerary-details {
  .driver-pick-up,
  .driver-unloading {
    text-align: center;
  }

  .sign-delivery {
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .car-itinerary-details-footer {
    .btn-primary {
      display: none;
    }
  }

  .action-bar {
    margin-top: 20px;
    text-align: center;
  }

  .content {
    padding: 0px;
    text-align: left;
    font-size: 14px;

    .data-cell {
      padding-right: 0px;

      &.data-cell-edit {
        height: 35px;
      }

      &.shipment-edit {
        margin-top: 6px;
      }

      &.shc {
        padding-left: 0px;
      }

      &.volume {
        text-align: center;
      }
    }

    &.shipments {
      .header {
        font-weight: bold;
      }

      .totals {
        font-weight: bold;
      }
    }

    .name {
      font-weight: bold;
    }
  }
}
</style>
