<template>
  <Error404WithCustomMessage v-if="invalidCmr" message="CMR doesn't exist. Please contact email sender for new link."/>
  <LayoutMain v-else>
        <span v-if="doneRendering">
            <span class="cmr" v-if="!invalidCmr">
                <a class="btn btn-primary download-cmr" :download="'cmr-' + cmrNumber + '.pdf'"
                   :href="'data:application/pdf;base64,' + src" target="_blank">Download</a>
                <a class="btn btn-primary print" href="#" @click="printPdf">Print</a>
                 <pdf ref="pdf" v-for="i in pages"
                      :key="i"
                      :page="i"
                      style="display: inline-block; width: 100%;"
                      :src="'data:application/pdf;base64,' + src"></pdf>
            </span>
        </span>
  </LayoutMain>
</template>

<script>

import QStepApi from '@/libraries/Api/QStep/Api'
import LayoutMain from '@/components/Layout/Main'
import Error404WithCustomMessage from '../../Core/LandingPage/Error404WithCustomMessage'
import PrintJs from 'print-js'
import Pdf from 'vue-pdf'

export default {
  components: {
    Error404WithCustomMessage,
    Pdf,
    LayoutMain
  },
  props: {
    carItineraryId: {
      type: Number,
      required: true
    },
    hash: {
      type: String,
      required: true
    }
  },
  name: 'Cmr',
  data () {
    return {
      pages: 0,
      src: '',
      invalidCmr: false,
      doneRendering: false,
      cmrNumber: this.carItineraryId
    }
  },
  mounted () {
    this.loadCmr()
  },
  methods: {
    printPdf (e) {
      PrintJs({ printable: this.src, type: 'pdf', base64: true })
    },
    handleError () {
      this.invalidCmr = true
    },
    loadCmr () {
      this.$showLoadingOverlay()
      const _self = this

      const qStepApi = new QStepApi(this.handleError)
      qStepApi.getPublicCmr(this.carItineraryId, this.hash).then(response => {
        if (response.data.data !== undefined) {
          _self.src = response.data.data.pdfStream
          _self.pages = response.data.data.pagesCount
          _self.cmrNumber = response.data.data.cmrNumber
        }
        _self.doneRendering = true
        this.$hideLoadingOverlay()
      }).catch(() => {
        _self.invalidCmr = true
        _self.doneRendering = true
        this.$hideLoadingOverlay()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.print {
  margin-left: 20px;
}

@media print {
  .flex.header, .download-cmr {
    display: none;
  }
}
</style>
