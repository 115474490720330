<template>
  <LayoutLoggedIn :doneRendering="doneRendering" :accessType="accessType" module="qdrive">
    <template v-slot:middle-left>
      <v-breadcrumbs :items="breadcrumb" v-if="isPrivate()">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
      <div>
        <v-btn color="warning" class="mr-2" @click="redirect('Loaded')" :outlined="selectedButton('loaded')">
          <span>Loaded</span>
        </v-btn>
        <v-btn @click="toggleFilter" color="success" class="mr-2" :outlined="selectedButton('filter')">
          <span>Filter</span>
        </v-btn>
        <v-btn v-if="unsignedCount" color="error" class="mr-2" @click="redirect('Unsigned')"
               :outlined="selectedButton('unsigned')">
          <span>unsigned ({{ unsignedCount }})</span>
        </v-btn>
        <v-btn v-if="!unsignedCount" color="default" class="mr-2" :disabled="!unsignedCount"
               :outlined="selectedButton('unsigned')">
          <span>unsigned (0)</span>
        </v-btn>
        <v-btn :outlined="selectedButton('seals')" :color="(toBeSealed) ? 'red' : 'success'" class="mr-2 float-end"
               @click="getToSeal">
          <span>Seal</span>
        </v-btn>
      </div>
    </template>
    <h2 v-if="isPublic()">Itinerary</h2>
    <div class="car-itineraries mt-2 pl-0">
      <div class="filter-wrapper" v-if="filterForm.open">
        <div>
          <FilterForm @filter="onSubmit" @reset="onReset" :slots="['fromDate', 'toDate', 'truckNumber', 'locations']">
            <template v-slot:fromDate>
              <DatePicker id="from-date" label="From date" v-model="filterForm.fromDate"></DatePicker>
            </template>
            <template v-slot:toDate>
              <DatePicker id="to-date" label="To date" v-model="filterForm.toDate"></DatePicker>
            </template>
            <template v-slot:truckNumber>
              <SelectInput
                  id="truck-number"
                  label="Truck number"
                  ref="truckNumber"
                  v-model="filterForm.truckNumber"
                  taggable multiple uppercase
              ></SelectInput>
            </template>
            <template v-slot:locations>
              <SelectInput
                  id="locations"
                  label="locations"
                  v-model="filterForm.locations.values"
                  :options="filterForm.locations.options"
                  taggable multiple uppercase
                  :maxLength="3"

                  @input="updateOptions"></SelectInput>
            </template>
          </FilterForm>
        </div>
        <v-divider></v-divider>
      </div>

      <div class="data">
        <div>
          <v-container>
            <v-layout row>
              <v-flex class="car-itinerary-not-found" v-if="hasCarItineraries && !searching">
                <div>
                  <Success v-if="countCarItineraries === 1">{{ countCarItineraries }} itineraries found</Success>
                  <Success v-else-if="countCarItineraries === 100"><strong>First {{ countCarItineraries }} itineraries
                    shown</strong>, optimize the search parameters to reduce the amount
                  </Success>
                  <Success class="mt-10 pl-0" v-else>{{ countCarItineraries }} itineraries found</Success>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row class="mt-10 pl-0">
              <v-flex v-if="!searching" class="car-itinerary">
                <span :class="{ 'even': index % 2 === 1, 'odd': index % 2 === 0}"
                      :key="carItineraryHelper(carItinerary).getCarItinerary().id"
                      v-for="(carItinerary, index) in filteredItems()">
                    <CarItineraryItem class="mb-5" :carItineraryHelper="carItineraryHelper(carItinerary)"
                                      :accessType="accessType" :encryptedData="encryptedData"
                    ></CarItineraryItem>
                </span>
                <v-btn v-if="!signedVisible && this.carItineraries.length - this.filteredItems().length > 0"
                       @click="showSigned" color="success" class="mr-2 showMore">
                  <span>Show Unloaded ( {{ this.carItineraries.length - this.filteredItems().length }}) </span>
                </v-btn>
                <v-btn v-if="signedVisible" @click="hideSigned" color="primary" class="mr-2">
                  <span>Hide Unloaded </span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex v-if="searching" class="searching">
                <Loading><strong>Searching for itinerary items</strong>, please wait!</Loading>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex class="car-itinerary-not-found" v-if="!hasCarItineraries && !searching">
                <Info>No itinerary items found</Info>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>
    </div>
  </LayoutLoggedIn>

</template>

<script>
import AccessType from '@/components/Core/AccessType'
import moment from 'moment'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import CarItineraryFilter from '@/libraries/Api/QStep/CarItineraryFilter/CarItineraryFilter'
import Parameter from '@/libraries/Api/QStep/CarItineraryFilter/Parameter'
import QStepApi from '@/libraries/Api/QStep/Api'
import CarItineraryItem from '@/components/CarItinerary/Item'
import Info from '@/components/Core/Message/Info'
import Loading from '@/components/Core/Message/Loading'
import Success from '@/components/Core/Message/Success'
import SelectInput from '@/components/Core/Input/Select'
import DatePicker from '@/components/Core/Input/DatePicker'
import FilterForm from '@/components/Core/Filter'
import CarItineraryHelper from '@/libraries/Models/CarItinerary'
import Utilities from '@/libraries/Util/Utilities'

export default {
  extends: AccessType,
  components: {
    LayoutLoggedIn, CarItineraryItem, Info, Loading, Success, SelectInput, DatePicker, FilterForm
  },
  name: 'List',
  data () {
    return {
      doneRendering: true,
      detailsVisible: false,
      signedVisible: false,
      carItineraries: [],
      unsignedCount: this.$store.getters.unsignedCount,
      searching: false,
      breadcrumb: this.$store.getters.breadcrumb,
      truckList: this.$store.getters.admin.truckList,
      toBeSealed: false,
      filterForm: {
        open: this.isPrivate(),
        fromDate: new Date(),
        toDate: new Date(),
        truckNumber: this.$store.getters.admin.truckList,
        cancelSource: null,
        locations:
            {
              options: [],
              values: []
            }
      }
    }
  },
  created () {
    this.$store.commit('breadcrumb', {
      text: this.$route.name.replace('CarItinerary', ''),
      route: this.$route.name.replace('CarItinerary', '').toLowerCase(),
      href: '/qdrive/itinerary/' + this.$route.name.replace('CarItinerary', '').toLowerCase()
    })
    if (this.isPrivate()) {
      this.loadFilterOptions()
    }
    this.loadCarItinerary()
    this.searching = true
  },
  computed: {
    adminName () {
      return this.$store.getters.admin.name
    },
    countCarItineraries () {
      return this.carItineraries.length
    },
    hasCarItineraries () {
      return this.countCarItineraries > 0
    }
  },

  methods: {
    redirect (page) {
      Utilities.redirectToPage(this, page)
    },
    selectedButton (route) {
      return Utilities.selectedButton(this, route, 'filter')
    },
    getToSeal () {
      Utilities.getToSeal(this)
    },
    carItineraryHelper (carItinerary) {
      return new CarItineraryHelper(carItinerary)
    },
    filteredItems () {
      return this.carItineraries.filter(item => {
        if (!this.signedVisible) {
          return !item.unLoadingCompletedAt
        }
        return item
      })
    },
    showSigned () {
      this.signedVisible = true
    },
    hideSigned () {
      this.signedVisible = false
    },
    hideFilter () {
      this.filterForm.open = false
    },
    onSubmit (e) {
      e.preventDefault()
      this.loaded = false
      this.signedVisible = false
      if (!this.filterForm.truckNumber.length) {
        this.searching = false
        this.$refs.truckNumber.showErrorHighlight()
        return false
      }
      this.searching = true
      this.loadCarItinerary()
      this.hideFilter()
      this.storeFilterOptions()
    },
    onReset (e) {
      e.preventDefault()
      this.loaded = false
      this.signedVisible = false
      this.filterForm.fromDate = new Date()
      this.filterForm.toDate = new Date()
      this.filterForm.truckNumber = this.$store.getters.truckNumber.toUpperCase()
      this.filterForm.locations.values = []
      this.searching = true
      this.loadCarItinerary()
      this.hideFilter()
      this.storeFilterOptions()
    },
    redirectToBulkSignatureAssignment (e) {
      e.preventDefault()
      this.$router.push({ name: 'ConsignorSignature-all' })
    },
    redirectToLoadedRoute (e) {
      e.preventDefault()
      this.$router.push({ name: 'CarItineraryLoaded' })
    },
    loadCarItinerary () {
      let carItinerariesPromise = null
      let carItinerariesSealStatusPromise = null

      const qStepApi = new QStepApi()

      const carItineraryFilter = new CarItineraryFilter()
      const columns = ['id', 'payerName', 'ccfn', 'std', 'truckNumber', 'trailerNumber', 'locations', 'destinationIata', 'weight', 'volume', 'pickUpDate', 'sortOrder']
      columns.forEach(function (column) {
        carItineraryFilter.addColumn(column)
      })

      let locations = ''
      let fromDate = ''
      let toDate = ''

      if (Array.isArray(this.filterForm.locations.values)) {
        locations = this.filterForm.locations.values.join(',')
      }

      if (this.filterForm.fromDate !== '') {
        fromDate = moment(this.filterForm.fromDate).format('YYYY-MM-DD')
      }

      if (this.filterForm.toDate !== '') {
        toDate = moment(this.filterForm.toDate).format('YYYY-MM-DD')
      }

      carItineraryFilter.addParameter(new Parameter('fromDate', fromDate))
      carItineraryFilter.addParameter(new Parameter('toDate', toDate))
      carItineraryFilter.addParameter(new Parameter('locations', locations))
      carItineraryFilter.addParameter(new Parameter('truckNumber', this.filterForm.truckNumber))
      carItineraryFilter.addParameter(new Parameter('sortOrder', 'DriverView'))

      if (this.isPrivate()) {
        carItinerariesPromise = qStepApi.getCarItineraries(carItineraryFilter)
      } else if (this.isPublic()) {
        carItinerariesPromise = qStepApi.getCarItineraries(carItineraryFilter, this.encryptedData)
      }

      const sealStatusFilter = new CarItineraryFilter()
      sealStatusFilter.addParameter(new Parameter('truckNumber', this.truckList))
      sealStatusFilter.addParameter(new Parameter('loaded', true))
      carItinerariesSealStatusPromise = qStepApi.getCarItinerariesSealStatus(sealStatusFilter, this.encryptedData)
      carItinerariesSealStatusPromise.then(response => {
        if (this.lodash.get(response.data.data, 'toBeSealed') !== undefined) {
          this.toBeSealed = response.data.data.toBeSealed
        }
      }).catch(() => {
        this.searching = false
      })

      carItinerariesPromise.then(response => {
        if (this.lodash.get(response.data.data, 'carItineraries') !== undefined) {
          this.carItineraries = response.data.data.carItineraries
        }
        this.unsignedCount = response.data.data.unsignedCount
        this.$store.commit('unsignedCount', response.data.data.unsignedCount)
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    toggleFilter () {
      this.$set(this.breadcrumb, 2, { text: 'Custom Filter', route: 'filter' })
      this.carItineraries = []
      this.unsigned = ''
      this.filterForm.open = !this.filterForm.open
    },

    updateOptions (searchQuery) {
      if (this.isPrivate()) {
        const qStepApi = new QStepApi()
        const _self = this

        const cancelSource = qStepApi.cancelToken()

        qStepApi.searchIata(searchQuery, cancelSource, this.cancelSource).then(response => {
          if (response.data.data !== undefined) {
            _self.filterForm.locations.options = response.data.data.map(data => {
              return data.code + ' - ' + data.city
            })
          }
        })

        this.cancelSource = cancelSource
      }
    },
    storeFilterOptions () {
      const data = {
        fromDate: this.filterForm.fromDate,
        toDate: this.filterForm.toDate,
        truckNumber: this.filterForm.truckNumber,
        locations: this.filterForm.locations.values
      }
      if (this.filterForm.truckNumber.length > 0) {
        this.$store.commit('truckNumber', this.filterForm.truckNumber)
      }

      this.$store.commit('filter', data)
    },
    loadFilterOptions () {
      const filter = this.$store.getters.filter

      if (this.lodash.isDate(filter.fromDate)) {
        this.filterForm.fromDate = filter.fromDate
      }

      if (this.lodash.isDate(filter.toDate)) {
        this.filterForm.toDate = filter.toDate
      }

      if (!this.lodash.isEmpty(filter.truckNumber)) {
        this.filterForm.truckNumber = this.$store.getters.admin.truckList
      }

      if (!this.lodash.isEmpty(filter.locations)) {
        this.filterForm.locations.values = filter.locations
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.data {
  .container {
    padding: 0px !important;
  }
}

.car-itineraries {
  .send-rfs-email {
    text-align: right;
  }

  .filter {
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;

    .footer {
      vertical-align: middle;
    }

    .v-text-field__details {
      display: none;
    }
  }

  .data {
    .car-itineraries-not-found {
      margin-top: 15px;
    }
  }
}
</style>
