<template>
    <v-form class="filter" @submit="onSubmit" @reset="onReset">
        <v-container grid-list-md>
            <v-layout row wrap>
                <v-flex class="xs12 sm6 md4 mb-4" :key="index" v-for="(slot, index) in slots">
                    <slot :name="slot"></slot>
                </v-flex>
                <v-flex :class="{'xs12 sm12 md12 mr-4 ml-4 mb-4': slots.length % 3 === 0, 'xs12 sm12 md4 mr-4 ml-4 mb-4': slots.length % 2 === 0}">
                    <div class="footer">
                        <v-btn type="submit" color="primary">Filter</v-btn>
                        <v-btn @click="onReset" class="reset-button" type="reset" color="error">Reset</v-btn>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>

export default {
  name: 'FilterForm',
  props: {
    slots: {
      type: Array,
      default: function () { return [] },
      required: true
    }
  },
  methods: {
    onSubmit (e) {
      e.preventDefault()
      this.$emit('filter', e)
    },
    onReset (e) {
      e.preventDefault()
      this.$emit('reset', e)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    form.filter {
        .reset-button {
            margin-left: 5px;
        }

        .container.grid-list-md .layout .flex {
            height: 60px;
        }

        .footer {
            margin-top: 10px;
        }
    }
</style>
