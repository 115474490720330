<template>
    <v-app>
        <v-container bg fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
                <v-flex>
                    <LayoutMain>
                        <slot></slot>
                    </LayoutMain>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>

<script>

import LayoutMain from '@/components/Layout/Main'

export default {
  components: {
    LayoutMain
  },
  name: 'LandingPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
