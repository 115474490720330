<template>
    <!--<Label :id="id" :text="label">-->
       <!--<v-text-field :id="id" v-model="value" type="password" @input="onInput"-->
                     <!--:placeholder="label"></v-text-field>-->
    <!--</Label>-->

    <v-text-field
        :id="id"
        :label="label"
        :hint="hint"
        v-model="value"
        v-bind="$attrs"
        type="password"
        @input="onInput"
        outlined>
    </v-text-field>

</template>

<script>

// import Label from '@/components/Core/Input/Core/Label'

export default {
  components: {
    // Label
  },
  name: 'PasswordInput',
  props: {
    id: {
      type: String,
      default: 'password-field',
      required: true
    },
    label: {
      type: String,
      default: 'password-field',
      required: true
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    valueP: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'valueP',
    event: 'change'
  },
  data () {
    return {
      value: this.valueP
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)

      this.value = value
    }
  },
  watch: {
    value: function (newValue) {
      this.$emit('change', newValue)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
