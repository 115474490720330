<template>
    <LoadingOverlay class="loading-overlay"
                    :active.sync="showLoadingOverlay">
    </LoadingOverlay>
</template>

<script>

import LoadingOverlay from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  data () {
    return {
      showLoadingOverlay: this.$isLoadingOverlayVisible
    }
  },
  components: {
    LoadingOverlay
  },
  name: 'LoadingPage',
  watch: {
    $isLoadingOverlayVisible: function (newVal) {
      this.showLoadingOverlay = newVal
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .vld-overlay.is-full-page.loading-overlay {
        z-index: 10000;
    }
</style>
