<template>
    <Info class="version-update" v-if="hasNewVersion">
        New version of the app is available, please <a class="refresh-link" href="#" @click="reload"> click here to
        refresh app</a>
    </Info>
</template>

<script>

import axios from 'axios'
import Info from '@/components/Core/Message/Info'
import Maintenance from '@/libraries/Util/Maintenance'

export default {
  components: {
    Info
  },
  name: 'VersionUpdate',
  props: {
    interval: {
      type: Number,
      default: 300000
    }
  },
  data () {
    return {
      interval$: null,
      hasNewVersion: false,
      version: ''
    }
  },
  beforeDestroy () {
    this.deactivatePoller()
  },
  mounted () {
    this.activatePoller()
  },
  methods: {
    deactivatePoller () {
      if (this.interval$ !== null) {
        clearInterval(this.interval$)
      }
    },
    activatePoller () {
      this.interval$ = setInterval(function () {
        axios.get('/version.js')
          .then(response => {
            if (response.data !== undefined) {
              if (this.version === '') {
                this.version = response.data
              } else {
                this.hasNewVersion = this.version !== response.data
              }
            }
          })
      }.bind(this), this.interval)
    },
    reload () {
      this.version = ''
      Maintenance.update()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .version-update {
        .refresh-link {
            color: #FFFFFF;
        }
    }
</style>
