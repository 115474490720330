<template>
  <layout-logged-in :doneRendering="doneRendering" :accessType="accessType" :showBackButton="showBackButton">
    <div class="signature">
      <v-container class="content">
        <v-flex>
          <h2>{{ text.title }}</h2>
        </v-flex>
      </v-container>
      <Failures v-model="errorMessages"></Failures>
      <v-container v-if="isSigned && !signatureConfirmation" class="content">
        <v-flex>
          <br/>
          <Info>{{ text.alreadySigned }}</Info>
          <v-btn color="primary" @click.native="toItinerary">Back to itinerary</v-btn>
        </v-flex>
      </v-container>

      <v-container v-if="signatureConfirmation" class="content">
        <v-layout row>
          <v-flex>
            <br/>
            <Success>{{ text.signatureSuccess }}</Success>
            <v-btn class="mt-7" color="primary" @click.native="toItinerary">Back to itinerary</v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container v-if="!isSigned && showForm" class="content">
        <v-layout row>
          <v-flex class="xs12 md12">
            <TextInput id="name" label="Name" :required="true" v-model="name" ref="name"></TextInput>
          </v-flex>
          <v-flex class="xs12 md12 mb-3">
            <SignaturePad :id="'car-itinerary-' + carItineraryId + '-signature'" label="Signature"
                          :imageType="imageType" :required="true" ref="signaturePad"/>
          </v-flex>
          <v-flex class="xs12 md12">
            <TextAreaInput id="notes" label="Notes" v-model="notes"></TextAreaInput>
          </v-flex>
          <v-flex class="xs12 md12">
            <NumberInput id="longitude" label="Longitude" v-model="longitude" :readonly="true"></NumberInput>
          </v-flex>
          <v-flex class="xs12 md12">
            <NumberInput id="latitude" label="Latitude" v-model="latitude" :readonly="true"></NumberInput>
          </v-flex>
          <v-flex class="xs12 md12">
            <TextInput id="signed-on" label="Signed On" v-model="signedOn" :readonly="true"></TextInput>
          </v-flex>
          <v-flex v-if="!isSigned">
            <v-btn class="save mr-2" color="primary" @click="sign">Save</v-btn>
            <v-btn class="save mr-2" v-if="!isSignatureRequired" color="secondary" @click="completeWithoutSignature">
              Save without signature
            </v-btn>
            <v-btn class="complete-without-signature" color="primary-outline" @click="takeSignatureLater">Take Signature
              Later
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </layout-logged-in>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import SignaturePad from '@/components/Core/Input/SignaturePad'
import TextInput from '@/components/Core/Input/Text'
import TextAreaInput from '@/components/Core/Input/TextArea'
import NumberInput from '@/components/Core/Input/Number'
import moment from 'moment'
import Failures from '@/components/Core/Message/Failures'
import Success from '@/components/Core/Message/Success'
import Info from '@/components/Core/Message/Info'

export default {
  extends: AccessType,
  components: {
    LayoutLoggedIn, SignaturePad, TextInput, TextAreaInput, Failures, Success, Info, NumberInput
  },
  props: {
    carItineraryId: {
      type: Number,
      required: true
    }
  },
  name: 'Signature',
  data () {
    return {
      doneRendering: false,
      text: {
        title: 'Signature',
        alreadySigned: 'Signature has already been given',
        signatureSuccess: 'Signature given successfully'
      },
      isSignatureRequired: true,
      showForm: false,
      isSigned: false,
      continueWithoutSignature: false,
      name: '',
      notes: '',
      longitude: null,
      latitude: null,
      signedOn: moment().format('YYYY-MM-DD HH:mm'),
      errorMessages: [],
      signatureConfirmation: false
    }
  },
  computed: {
    imageType () {
      return SignaturePad.SVG
    },
    showBackButton () {
      return !this.signatureConfirmation && !this.isSigned
    }
  },
  methods: {
    signatureData () {
      return {
        continueWithoutSignature: this.continueWithoutSignature,
        carItineraryId: this.lodash.parseInt(this.carItineraryId),
        name: this.name,
        signature: this.$refs.signaturePad.getBase64Encoding(),
        extension: this.$refs.signaturePad.getExtension(),
        notes: this.notes,
        longitude: this.longitude,
        latitude: this.latitude,
        signedOn: this.signedOn
      }
    },
    clearErrors () {
      this.errorMessages = []
      this.$refs.name.hideErrorHighlight()
      this.$refs.signaturePad.hideErrorHighlight()
    },
    hasErrors () {
      return this.errorMessages.length > 0
    },
    validate () {
      this.clearErrors()
      if (this.lodash.isEmpty(this.name)) {
        this.errorMessages.push('Name is required.')
        this.$refs.name.showErrorHighlight()
      }

      if (!this.$refs.signaturePad.hasImage()) {
        this.errorMessages.push('Signature is required.')
        this.$refs.signaturePad.showErrorHighlight()
      }

      return !this.hasErrors()
    },
    showConfirmation () {
      this.signatureConfirmation = true
      this.isSigned = true
    },
    toItinerary () {
      const route = this.$store.getters.breadcrumb[2].route.charAt(0).toUpperCase() + this.$store.getters.breadcrumb[2].route.slice(1)
      if (this.isPrivate()) {
        this.$router.push({ name: 'CarItinerary' + route })
      } else if (this.isPublic()) {
        this.$router.push({ name: 'EncryptedCarItinerary' + route, params: { encryptedData: this.encryptedData } })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.signature {
  .save,
  .complete-without-signature {
    margin-bottom: 10px;
  }
}
</style>
