<template>
  <div class="layout-main-wrapper">
    <VersionUpdate v-if="environment === 'production'"></VersionUpdate>
    <LoadingOverlay></LoadingOverlay>
    <v-main class="layout-main mt-4">
      <template class="mp-16">
        <div>
          <v-app-bar color="white" elevate-on-scroll>
            <v-toolbar-title class="mt-0"><img class="logo mt-2" alt="Qstep logo" :src="logo.src"></v-toolbar-title>
            <v-avatar
                color="primary"
                size="42"
                class="ml-2 mr-4 mt-0 appIcon"
                @click="applications"
                v-if="permission"
                :style="{ cursor: 'pointer'}"
            >
              <v-icon color="white">mdi-apps</v-icon>
            </v-avatar>
            <v-spacer></v-spacer>

            <v-menu
                class="mt-0"
                right
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                    color="primary"
                    size="20"
                    v-if="permission"
                >
                  <v-btn
                      x-small
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </v-avatar>
                <span class="blue--text text--darken-4 mr-1 mt-5 text-right" v-on="on"
                      v-if="module && module === 'qdrive' && truckNumber">{{ adminName }} <br/>{{ truckNumber }}</span>
                <span class="blue--text text--darken-4 mr-1 mt-0 text-right" v-on="on"
                      v-if="!module">{{ adminName }}</span>
              </template>
              <v-list>
                <v-list-item key="change" @click="changeTruck">
                  <v-list-item-title color="primary">Change Truck</v-list-item-title>
                </v-list-item>
                <v-list-item key="logout" @click="logout">
                  <v-list-item-title> Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>
        </div>
      </template>
      <v-container class="page mt-0">
            <v-flex class="middle">
              <slot></slot>
            </v-flex>
            <v-flex class="footer">
              <slot name="footer"></slot>
            </v-flex>
          </v-container>
        </v-main>
  </div>
</template>

<script>

import VersionUpdate from '@/components/Core/VersionUpdate'
import LoadingOverlay from '@/components/Core/LoadingOverlay'
import Authentication from '@/libraries/Authentication/Authentication'
import QStepApi from '@/libraries/Api/QStep/Api'

export default {
  components: {
    VersionUpdate, LoadingOverlay
  },
  props: {
    hideScrollBar: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      required: false
    },
    permission: {
      type: Boolean
    }
  },
  model: {
    prop: 'show',
    event: 'visibility'
  },
  mounted () {
    this.handleHideScrollBar(this.hideScrollBar)
  },
  name: 'Main',
  data () {
    return {
      environment: process.env.NODE_ENV,
      logo: {
        src: require('@/assets/images/qstep-logo.png')
      }
    }
  },
  methods: {
    applications: function () {
      this.$router.push({ name: 'Applications' })
    },
    logout: function () {
      const authentication = new Authentication()
      authentication.logout()
    },
    changeTruck () {
      let changeTruckPromise = null
      const qStepApi = new QStepApi()
      changeTruckPromise = qStepApi.ChangeTruck(this.$store.getters.admin.truckList.map(item => item).join(','))
      changeTruckPromise.then(response => {
        this.$store.commit('truckList', [''])
        this.$router.push({ name: 'TruckNumber' })
      }).catch(() => {
        this.$router.push({ name: 'TruckNumber' })
      })
    },
    handleHideScrollBar (hideScrollBar) {
      if (hideScrollBar) {
        document.querySelector('html').classList.add('hide-scroll-bar')
      } else {
        document.querySelector('html').classList.remove('hide-scroll-bar')
      }
    }
  },
  watch: {
    hideScrollBar: function (hideScrollBar) {
      this.handleHideScrollBar(hideScrollBar)
    }
  },
  computed: {
    adminName () {
      return this.$store.getters.admin.name
    },
    truckNumber () {
      return this.$store.getters.admin.truckList.map(item => item).join('/')
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.layout-main-wrapper {
  width: 100%;

  .container.page.mt-0 {
    padding: 0px;
  }

  .layout-main {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    .logo {
      padding-top: 5px;
    }

    .header-wrapper {
      position: relative;
      text-align: left;
    }

    .admin-name, .truck-number {
      position: absolute;
      right: 0px;
    }

    .truck-number {
      top: 22px;
      font-size: smaller;
    }
  }
}

@media only screen and (max-width: 600px) {
  .v-toolbar__title {
    width: 105px !important;
  }
}

</style>

<style lang="scss">
html.hide-scroll-bar {
  overflow: hidden !important;
}
</style>
