<template>
  <LayoutMain v-if="isDoneRendering()" :module="module" :permission="isPrivate()">
    <v-container class="layout-logged-in">
      <v-flex class="header">
        <slot name="header" module="qdrive"></slot>
      </v-flex>
      <v-flex class="middle">
        <v-container>
          <v-layout row class="mt-2 mb-4">
            <v-flex class="middle-left">
              <slot name="middle-left">
                <v-btn class="back-button mr-2" v-if="showBackButton" @click="goBack" color="primary">Back</v-btn>
              </slot>
            </v-flex>
          </v-layout>
          <slot></slot>
          <v-flex class="footer" v-if="isPrivate()">
            <slot name="footer"></slot>
          </v-flex>
        </v-container>
      </v-flex>
    </v-container>
  </LayoutMain>
</template>

<script>

import Authentication from '@/libraries/Authentication/Authentication'
import QStepApi from '@/libraries/Api/QStep/Api'
import LayoutMain from '@/components/Layout/Main'
import router from '@/router'

export default {
  components: {
    LayoutMain
  },
  name: 'LoggedIn',
  props: {
    accessType: {
      type: String,
      required: false,
      default: 'private'
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    doneRendering: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      internalDoneRendering: false,
      items: [],
      showApplicationButton: this.$store.getters.applicationCount > 1
    }
  },
  beforeMount () {
    if (this.isPrivate()) {
      this.$showLoadingOverlay()
      if (!this.$store.getters.hasAdmin) {
        const _self = this
        const store = this.$store
        const qStepApi = new QStepApi()
        qStepApi.getAdmin().then(function (response) {
          store.commit('admin', response.data)
          _self.setDoneRendering()
        })
      } else {
        this.setDoneRendering()
      }
    }
    this.setDoneRendering()
  },
  methods: {
    logout: function () {
      const authentication = new Authentication()
      authentication.logout()
    },
    goBack: function () {
      this.$router.go(-1)
    },
    applications: function () {
      this.$router.push({ name: 'Applications' })
    },
    hideApplicationButton () {
      this.showApplicationButton = false
    },
    isPrivate () {
      return this.accessType === 'private'
    },
    setDoneRendering () {
      this.internalDoneRendering = true
    },
    isDoneRendering () {
      const isDoneRendering = this.doneRendering && this.internalDoneRendering
      if (isDoneRendering) {
        this.$hideLoadingOverlay()
      }

      return isDoneRendering
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.layout-logged-in {
  .middle {
    border-top: 1px solid rgba(0, 0, 0, .1);

    .middle-right {
      .logout {
        text-align: right;
      }
    }

    .middle-left {
      text-align: left;
    }
  }

  .footer {
    margin-top: 10px;
    margin-bottom: 40px;

    .status {
      margin-top: 10px;
      margin-bottom: -10px;
      text-align: left;
      font-size: 12px;
      color: #cccccc;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
