<script>

export default {
  props: {
    accessType: {
      type: String,
      required: false,
      default: 'private'
    },
    encryptedData: {
      type: String,
      required: false,
      default: ''
    }
  },
  name: 'AccessType',
  methods: {
    isPrivate () {
      return this.accessType === 'private'
    },
    isPublic () {
      return this.accessType === 'public'
    },
    hasEncryptedData () {
      return this.encryptedData !== ''
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
