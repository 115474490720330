<template>
   <div class="message failure">
       <v-alert show type="error">
          <slot>Something went terribly wrong, please try again</slot>
       </v-alert>
   </div>
</template>

<script>

export default {
  name: 'Failure'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
