<template>
    <div class="messages failures">
        <div  v-for="(errorMessage, index) in errorMessagesD" v-bind:key="index">
             <Failure>{{ errorMessage }}</Failure>
        </div>
    </div>
</template>

<script>

import Failure from '@/components/Core/Message/Failure'

export default {
  components: {
    Failure
  },
  props: {
    errorMessages: {
      type: Array,
      default: function () { return [] }
    }
  },
  model: {
    prop: 'errorMessages',
    event: 'change'
  },
  name: 'Failures',
  data () {
    return {
      errorMessagesD: this.errorMessages
    }
  },
  watch: {
    errorMessages: function (newVal) {
      this.errorMessagesD = newVal
      this.$emit('change', newVal)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
