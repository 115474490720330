<template>
    <div class="clock">
        {{ getDisplayTime() }}
    </div>
</template>

<script>

export default {
  name: 'Clock',
  props: {
    time: {
      type: Date,
      default: new Date(),
      required: false
    }
  },
  data () {
    return {
      initBrowserTime: new Date(),
      initTime: this.time,
      currentTime: new Date()
    }
  },
  mounted () {
    const _self = this
    setInterval(function () {
      const browserTime = new Date()
      _self.currentTime = new Date(browserTime.setSeconds(browserTime.getSeconds() + 1 + _self.getBrowserTimeDiff()))
      _self.$emit('timeChanged', _self.currentTime)
    }, 1000)
  },
  methods: {
    getDisplayTime () {
      const time = this.currentTime

      return (time.getHours() >= 10 ? time.getHours() : '0' + time.getHours()) + ':' +
        (time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes()) + ':' +
        (time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds())
    },
    getBrowserTimeDiff () {
      const diffTime = this.initTime.getTime() - this.initBrowserTime.getTime()
      const diffSeconds = diffTime / 1000

      if (diffSeconds < 5) {
        return 0
      }

      return diffSeconds
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .clock {
        text-align: center;
    }
</style>
