<template>
  <ErrorBase>
    <template v-slot:errorMessage>404 - {{ message }}</template>
  </ErrorBase>
</template>

<script>

import ErrorBase from './ErrorBase'

export default {
  extends: ErrorBase,
  components: {
    ErrorBase
  },
  props: {
    message: {
      type: String,
      default: 'Record not found.'
    }
  },
  name: 'Error404WithCustomMessage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
