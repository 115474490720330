<template>
  <layout-logged-in :doneRendering="doneRendering">
    <div class="truck-number mt-10 p-0">
      <div class="filter">
        <template>
          <v-card>
            <v-card-title class="headline blue dark white--text">
              TRUCK
            </v-card-title>
            <v-card-text>
            </v-card-text>
            <v-container>
              <v-row no-gutters v-for="(item, i) in inputs" class="grey lighten-5" :key="i" cols="12" sm="2" md="11">
                <v-col>
                  <v-autocomplete
                      clearable
                      :class="typeLabel(i)"
                      disable-lookup
                      :rules="[v => !!v || 'Required']"
                      v-model="model[i]"
                      :items="itemsSelected(i)"
                      :loading="isLoading[i]"
                      :search-input.sync="search[i]"
                      @keydown="searchVehicleKey(i,$event)"
                      color="black"
                      @change="update(i)"
                      hide-no-data
                      hide-selected
                      item-text="Code"
                      item-value="Code"
                      :label="typeLabel(i)"
                      placeholder="Start typing code"
                      data-cy='select-input'
                      :prepend-icon="typeIcon(i)"
                  ></v-autocomplete>
                </v-col>
                <v-col
                    cols="6"
                    md="1"
                    sm="2"

                    v-if="i > 0"
                >
                  <v-btn
                      small
                      color="error"
                      class="white--text float-right mt-5"
                      @click="removeItem(i)"
                  >Remove
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  id="addInput"
                  color="success"
                  class="white--text float-right addInput"
                  @click="addNewItem()"
              >
                Add
                <v-icon right color="white">
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                  :disabled="!submit"
                  color="primary"
                  class="selectTruck"
                  @click="onSubmit"
              >
                ENTER
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </div>
    </div>
  </layout-logged-in>
</template>

<script>

import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import QStepApi from '@/libraries/Api/QStep/Api'

export default {
  components: {
    LayoutLoggedIn
  },
  name: 'TruckNumber',
  data: () => {
    return {
      doneRendering: true,
      inputs: [true],
      descriptionLimit: 15,
      entries: [],
      isLoading: [],
      model: [],
      search: [],
      submit: false,
      awaitingSearch: false,
      currenSearchInput: null
    }
  },
  methods: {
    update (i) {
      this.removeDuplicateNumber()
      if (typeof (this.model[0]) === 'undefined') {
        this.submit = false
      } else {
        this.submit = true
      }
    },
    addNewItem () {
      this.inputs.push(true)
      this.$set(this.entries, this.entries.length, [{ code: '' }])
    },
    removeDuplicateNumber () {
      const newList = []
      const countList = []
      let checkDuplicate = false

      this.model.forEach((item, index) => {
        if (!countList[item]) {
          countList[item] = 0
        }
        countList[item] = parseInt(countList[item]) + 1
        if (countList[item] > 1) {
          checkDuplicate = true
        }
      })

      // run if duplicate found in the list.
      if (checkDuplicate) {
        this.model.forEach((item, index) => {
          if (newList.indexOf(item) === -1 && item != null) {
            newList.push(item)
          } else {
            // execute if duplicate index matches.
            if (countList[item] > 1) {
              this.model[index] = ''
              alert('Truck / Trailer already exists in the list.')
              this.removeItem(index)
            }
          }
        })
      }
    },
    removeItem (i) {
      this.model.splice(i, 1)
      this.search.splice(i, 1)
      this.inputs.splice(i, 1)
    },
    onSubmit () {
      if (this.model.filter(item => item)) {
        if (this.search[0] !== 'undefined') {
          this.$store.commit('truckNumber', this.model[0])
          this.$store.commit('truckList', this.model.filter(item => item))
          this.$router.push({ name: 'CarItineraryLoaded' })
        }
      }
    },
    searchVehicleKey (i, e) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        // update cache in future
      } else {
        this.searchVehicle(this.search[i], i)
      }
    },
    itemsSelected (i) {
      return this.entries[i].map(entry => {
        const Code = entry.regnr || entry.code
        return Object.assign({}, entry, { Code })
      })
    },
    typeLabel (i) {
      return i === 0 ? 'Truck' : 'Trailer'
    },
    typeIcon (i) {
      return i === 0 ? 'mdi-truck' : 'mdi-truck-trailer'
    },
    searchVehicle (val, i) {
      if (val === null) return
      if (val.length < 1) return
      let carItinerariesPromise = null
      this.isLoading[i] = true
      const qStepApi = new QStepApi()
      const typeName = i === 0 ? 'trucks' : 'trailers'
      carItinerariesPromise = qStepApi.getCarListByCode(val, typeName, this.encryptedData)
      carItinerariesPromise.then(response => {
        this.$set(this.entries, i, response.data.data)
        this.isLoading[i] = false
      }).catch(() => {
        this.isLoading[i] = false
      })
    }
  },
  created () {
    this.$store.getters.truckList.map((item, index) => {
      if (!this.entries[index]) {
        this.entries[index] = []
      }
      this.entries[index].push({ code: item })
    })

    this.model = this.$store.getters.truckList.map(item => {
      return item
    })

    this.inputs = this.$store.getters.truckList.map(item => {
      return true
    })
    this.submit = true
  },
  computed: {
    fields (i) {
      if (!this.model) return []
      return Object.keys(this.model[i]).map(key => {
        return {
          key,
          value: this.model[i][key] || 'n/a'
        }
      })
    },
    items (i) {
      return this.entries.map(entry => {
        const Code = entry.regnr || entry.code
        return Object.assign({}, entry, { Code })
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.middle {
  .container {
    padding: 4px
  }
}

</style>
