<template>
  <ErrorBase>
    <template v-slot:errorMessage>404 - Page not found!</template>
    <template v-slot:additionalItems>
    </template>
  </ErrorBase>
</template>

<script>

import ErrorBase from './ErrorBase'

export default {
  extends: ErrorBase,
  components: {
    ErrorBase
  },
  name: 'Error404'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
