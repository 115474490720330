<template>
  <LayoutLoggedIn :doneRendering="doneRendering" :accessType="accessType">
    <template v-slot:middle-left v-if="isPrivate()">
      <slot name="middle-left">
        <v-btn class="back-button mr-2" v-if="showBackButton" @click="goBack" color="primary">Back</v-btn>
      </slot>
      <v-btn color="error" class="mr-2" @click="goToUnsigned">
        <span>unsigned ({{ unsignedCount }})</span>
      </v-btn>
      <v-btn v-if="!unsignedCount" color="default" class="mr-2">
        <span>unsigned (0)</span>
      </v-btn>
    </template>
    <v-container v-if="updatingPickup" class="content">
      <v-layout row>
        <v-flex>
          <Info>{{ text.updatingPickup }}</Info>
          <v-progress-linear color="info" :indeterminate="true"></v-progress-linear>
        </v-flex>
      </v-layout>
    </v-container>
    <div class="signature">
      <v-container class="content">
        <v-flex>
          <h2>{{ text.title }}</h2>
        </v-flex>
      </v-container>
      <Failures v-model="errorMessages"></Failures>
      <v-container v-if="isSigned && !signatureConfirmation" class="content">
        <v-flex>
          <br/>
          <Info>{{ text.alreadySigned }}</Info>
          <v-btn color="primary" @click.native="toItinerary">Back to itinerary</v-btn>
        </v-flex>
      </v-container>

      <v-container v-if="signatureConfirmation" class="content">
        <v-layout row>
          <v-flex>
            <br/>
            <Success>{{ text.signatureSuccess }}</Success>
            <v-btn class="mt-7" color="primary" @click.native="toItinerary">Back to itinerary</v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container v-if="!isSigned && showForm" class="content">
        <v-layout row>
          <v-flex class="xs12 md12">
            <TextInput id="name" label="Name" :required="true" v-model="name" ref="name"></TextInput>
          </v-flex>
          <v-flex class="xs12 md12 mb-3">
            <SignaturePad :id="'car-itinerary-all-signature'" label="Signature" :imageType="imageType" :required="true"
                          ref="signaturePad"/>
          </v-flex>
          <v-flex class="xs12 md12">
            <TextAreaInput id="notes" label="Notes" v-model="notes"></TextAreaInput>
          </v-flex>
          <v-flex class="xs12 md12">
            <TextInput id="signed-on" label="Signed On" v-model="signedOn" :readonly="true"></TextInput>
          </v-flex>
          <v-flex v-if="!isSigned">
            <v-btn class="save mr-2" color="primary" @click="sign">Save to All</v-btn>
            <v-btn class="save mr-2 btn-primary" color="secondary" @click="completeWithoutSignatureAll">Save All without
              Signature
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </LayoutLoggedIn>
</template>

<script>
import AccessType from '@/components/Core/AccessType'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import SignaturePad from '@/components/Core/Input/SignaturePad'
import TextInput from '@/components/Core/Input/Text'
import TextAreaInput from '@/components/Core/Input/TextArea'
import moment from 'moment'
import Failures from '@/components/Core/Message/Failures'
import Success from '@/components/Core/Message/Success'
import Info from '@/components/Core/Message/Info'

export default {
  extends: AccessType,
  components: {
    LayoutLoggedIn, SignaturePad, TextInput, TextAreaInput, Failures, Success, Info
  },
  props: {
    carItineraryId: {
      type: Number
    }
  },
  name: 'SignatureAll',
  data () {
    return {
      unsignedCount: this.$store.getters.unsignedCount,
      searching: false,
      unsigned: false,
      doneRendering: false,
      updatingPickup: false,
      text: {
        title: 'Signature',
        alreadySigned: 'Signature has already been given',
        signatureSuccess: 'Signature given successfully',
        updatingPickup: 'Compiling all itinerary items, please wait!'
      },
      isSignatureRequired: true,
      showForm: false,
      isSigned: false,
      continueWithoutSignature: false,
      name: '',
      notes: '',
      longitude: null,
      latitude: null,
      signedOn: moment().format('YYYY-MM-DD HH:mm'),
      errorMessages: [],
      signatureConfirmation: false
    }
  },
  computed: {
    imageType () {
      return SignaturePad.SVG
    },
    showBackButton () {
      return !this.signatureConfirmation && !this.isSigned
    }
  },
  methods: {
    goToUnsigned () {
      this.$router.push({ path: '/itinerary/unsigned', force: true })
    },
    goBack: function () {
      this.$router.go(-1)
    },
    signatureData (takeSignatureLater = false) {
      return {
        takeSignatureLater: takeSignatureLater,
        continueWithoutSignature: this.continueWithoutSignature,
        carItineraryId: null,
        name: this.name,
        signature: this.$refs.signaturePad.getBase64Encoding(),
        extension: this.$refs.signaturePad.getExtension(),
        notes: this.notes,
        longitude: this.longitude,
        latitude: this.latitude,
        signedOn: this.signedOn
      }
    },
    clearErrors () {
      this.errorMessages = []
      this.$refs.name.hideErrorHighlight()
      this.$refs.signaturePad.hideErrorHighlight()
    },
    hasErrors () {
      return this.errorMessages.length > 0
    },
    validate () {
      this.clearErrors()
      if (this.lodash.isEmpty(this.name)) {
        this.errorMessages.push('Name is required.')
        this.$refs.name.showErrorHighlight()
      }

      if (!this.$refs.signaturePad.hasImage()) {
        this.errorMessages.push('Signature is required.')
        this.$refs.signaturePad.showErrorHighlight()
      }

      return !this.hasErrors()
    },
    showConfirmation () {
      this.signatureConfirmation = true
      this.isSigned = true
    },
    toItinerary () {
      const route = this.$store.getters.breadcrumb[2].route.charAt(0).toUpperCase() + this.$store.getters.breadcrumb[2].route.slice(1)
      if (this.isPrivate()) {
        this.$router.push({ name: 'CarItinerary' + route })
      } else if (this.isPublic()) {
        this.$router.push({ name: 'EncryptedCarItinerary' + route, params: { encryptedData: this.encryptedData } })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.signature {
  .save,
  .complete-without-signature {
    margin-bottom: 10px;
  }
}
</style>
