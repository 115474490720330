<template>
    <layout-logged-in :doneRendering="doneRendering" :accessType="accessType" :showBackButton="true">
        <h2>Select itinerary CMR liabilities</h2>
        <v-container>
            <span :key="index" v-for="(liability, index) in liabilities">
                <v-checkbox :label="liability.name" :value="!liability.checked" :input-value="liability.id" @change="select($event, liability.id)"></v-checkbox>
            </span>
            <v-btn color="primary" @click="save">Save</v-btn>
        </v-container>
    </layout-logged-in>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import LayoutLoggedIn from '@/components/Layout/LoggedIn'
import QStepApi from '@/libraries/Api/QStep/Api'

export default {
  extends: AccessType,
  components: {
    LayoutLoggedIn
  },
  props: {
    carItineraryId: {
      type: Number,
      required: true
    }
  },
  name: 'List',
  data () {
    return {
      doneRendering: false,
      liabilities: [],
      selectStatus: []
    }
  },
  created () {
    this.updateLiabilities()
  },
  methods: {
    select (selected, liabilityId) {
      if (selected !== null) {
        this.selectStatus[liabilityId] = true
      } else {
        this.selectStatus[liabilityId] = false
      }
    },
    save () {
      const selectedLiabilityIds = []

      this.selectStatus.forEach(function (selected, liabilityId) {
        if (selected) {
          selectedLiabilityIds.push(liabilityId)
        }
      })

      this.$store.commit('selectedCmrLiabilityIds', selectedLiabilityIds)

      if (this.isPrivate()) {
        this.$router.push({ name: 'ConsignorSignature', params: { carItineraryId: this.carItineraryId } })
      } else if (this.isPublic()) {
        this.$router.push({ name: 'EncryptedConsignorSignature', params: { carItineraryId: this.carItineraryId, encryptedData: this.encryptedData } })
      }
    },
    updateLiabilities () {
      const qStepApi = new QStepApi()
      const _self = this

      this.$showLoadingOverlay()

      let cmrLiabilitiesPromise = null

      if (this.isPrivate()) {
        cmrLiabilitiesPromise = qStepApi.getCmrLiabilities()
      } else if (this.isPublic()) {
        cmrLiabilitiesPromise = qStepApi.getCmrLiabilities(this.encryptedData)
      }

      cmrLiabilitiesPromise.then(response => {
        if (response.data.data !== undefined) {
          _self.liabilities = response.data.data
          _self.liabilities.forEach(function (liability) {
            if (liability.checked) {
              _self.selectStatus[liability.id] = true
            }
          })
        }
        _self.doneRendering = true
        this.$hideLoadingOverlay()
      }).catch(() => {
        _self.doneRendering = true
        this.$hideLoadingOverlay()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .car-itineraries {
        .filter {
            max-width: 1024px;
            margin: 0 auto;
            text-align: center;

            .footer {
                vertical-align: middle;
            }

            .v-text-field__details {
                display: none;
            }
        }

        .data {
            .car-itineraries-not-found {
                margin-top: 15px;
            }
        }
    }
</style>
