<script>

export default {
  name: 'Input',
  props: {
    id: {
      type: String,
      default: 'input-field',
      required: true
    },
    label: {
      type: String,
      default: 'input-field',
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    indicateRequired: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      errorHighlight: false
    }
  },
  computed: {
    labelRequired () {
      if (this.required && this.indicateRequired) {
        return this.label + ' *'
      }

      return this.label
    }
  },
  methods: {
    showErrorHighlight () {
      this.errorHighlight = true
    },
    hideErrorHighlight () {
      this.errorHighlight = false
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
