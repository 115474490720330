<script>
import QStepApi from '@/libraries/Api/QStep/Api'
import SignAll from '@/components/CarItinerary/SignAll'

export default {
  components: { SignAll },
  extends: SignAll,
  data () {
    return {
      isSignatureRequired: false,
      text: {
        title: 'Confirmation of pick up & unload for All orders',
        alreadySigned: 'Consignor has already signed the pick up confirmation',
        signatureSuccess: 'All orders signed successfully'
      }
    }
  },
  name: 'ConsignorSignAll',
  mounted () {
    const _self = this
    this.$showLoadingOverlay()
    _self.latitude = 0
    _self.longitude = 0
    _self.showForm = true
    _self.all = true
    this.$hideLoadingOverlay()
    _self.doneRendering = true
  },
  methods: {
    sign () {
      const _self = this
      _self.updatingPickup = true
      if (this.validate()) {
        this.continueWithoutSignature = false
        const qStepApi = new QStepApi()
        this.$showLoadingOverlay()
        _self.loading = true
        let pickUpPromise = null
        const signatureData = this.signatureData()
        signatureData.truckNumber = this.$store.getters.truckNumber

        if (this.isPrivate()) {
          pickUpPromise = qStepApi.bulkSign(signatureData, {}, this.$store.getters.selectedCmrLiabilityIds)
        } else if (this.isPublic()) {
          pickUpPromise = qStepApi.bulkSign(signatureData, {}, this.$store.getters.selectedCmrLiabilityIds, this.encryptedData)
        }

        pickUpPromise.then(response => {
          _self.showConfirmation()
          this.$hideLoadingOverlay()
          _self.doneRendering = true
          _self.updatingPickup = false
          this.$store.commit('unsignedCount', 0)
        }).catch(() => {
          _self.errorMessages.push('Failed to submit the signature.')
          this.$hideLoadingOverlay()
          _self.doneRendering = true
          _self.updatingPickup = false
        })
      }
      _self.updatingPickup = false
    },
    completeWithoutSignatureAll () {
      const _self = this
      _self.errorMessages = []
      _self.updatingPickup = true
      const qStepApi = new QStepApi()
      this.$showLoadingOverlay()
      _self.loading = true
      let pickUpPromise = null
      if (this.isPrivate()) {
        pickUpPromise = qStepApi.bulkSign(this.signatureData(true), {}, this.$store.getters.selectedCmrLiabilityIds)
      } else if (this.isPublic()) {
        pickUpPromise = qStepApi.bulkSign(this.signatureData(true), {}, this.$store.getters.selectedCmrLiabilityIds, this.encryptedData)
      }
      pickUpPromise.then(response => {
        _self.showConfirmation()
        this.$hideLoadingOverlay()
        _self.doneRendering = true
        _self.updatingPickup = false
        this.$store.commit('unsignedCount', 0)
      }).catch(() => {
        _self.errorMessages.push('Failed to submit the signature.')
        this.$hideLoadingOverlay()
        _self.doneRendering = true
        _self.updatingPickup = false
      })
      _self.updatingPickup = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
