<template>
    <div class="static-progress-bar" :style="{ border: '1px solid' + color, width: width, padding: padding }">
        <v-progress-linear
                :buffer-value="progressPercentage"
                :height="height"
                color="transparent"
                :background-color="color">
            <slot> </slot>
        </v-progress-linear>
    </div>
</template>

<script>

export default {
  name: 'StaticProgressBar',
  props: {
    progressPercentage: {
      type: Number,
      default: 0,
      required: false
    },
    height: {
      type: String,
      default: '3px',
      required: false
    },
    width: {
      type: String,
      default: '100%',
      required: false
    },
    color: {
      type: String,
      default: '#4caf50',
      required: false
    },
    padding: {
      type: String,
      default: '2px',
      required: false
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .static-progress-bar {
        display: inline-block;
        border-radius: 4px;
    }
</style>
