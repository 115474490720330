<template>
    <div :class="['edit-shipment-number-field', 'number-field-' + name]">
        <span v-if="!carItineraryHelper.isPickedUp() && shipment.isCreatedManually">
             <NumberInput :id="name" label="" v-model.number="valueD" height="10px" :valueOnEmpty="0"
                          @input="onInput"></NumberInput>
            </span>
        <span v-else>
           {{ textValue }}
        </span>
    </div>
</template>

<script>

import NumberInput from '@/components/Core/Input/Number'

export default {
  components: {
    NumberInput
  },
  props: {
    carItineraryHelper: {
      type: Object,
      required: true
    },
    shipment: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      require: true
    },
    textValue: {
      type: String,
      require: true
    },
    shipmentId: {
      type: Number,
      require: true
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  name: 'NumberField',
  data () {
    return {
      valueD: this.value
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', { value: value, shipmentId: this.shipmentId })

      this.valueD = parseInt(value)
    }
  },
  watch: {
    value: function (newValue) {
      this.$emit('change', newValue)
    }
  }
}
</script>

<style lang="scss">
    .edit-shipment-number-field {
        margin-left: 2px;
        margin-right: 2px;
        font-size: 14px;

        .v-text-field__details {
            display: none;
        }

        .v-text-field__slot {
            height: 32px;
            text-align: center;
            font-size: 14px;

        }

        .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
            padding: 0 4px;
            text-align: center;
            height: 35px;

            input {
                text-align: center;
            }
        }

        .v-text-field--outlined {
            fieldset {
                height: 35px;
                padding: 0px;
            }
        }
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
