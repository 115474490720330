<template>
  <ErrorBase>
    <template v-slot:errorMessage>419 - URL is expired!</template>
  </ErrorBase>
</template>

<script>

import ErrorBase from './ErrorBase'

export default {
  components: {
    ErrorBase
  },
  name: 'Error419'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
