<template>
       <v-textarea :id="id"
                   v-model="value"
                   v-bind="$attrs"
                   type="text"
                   @input="onInput"
                   outlined
                   :label="label"></v-textarea>

</template>

<script>

export default {
  name: 'TextArea',
  props: {
    id: {
      type: String,
      default: 'text-field',
      required: true
    },
    label: {
      type: String,
      default: 'text-field',
      required: true
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    valueP: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'valueP',
    event: 'change'
  },
  data () {
    return {
      value: this.valueP
    }
  },
  methods: {
    onInput (value) {
      if (this.uppercase) {
        value = value.toUpperCase()
      }

      this.$emit('input', value)

      this.value = value
    }
  },
  watch: {
    value: function (newValue) {
      this.$emit('change', newValue)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
