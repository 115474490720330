<template>
    <v-text-field ref="textField" v-bind="$attrs" @keydown="onKeyDown">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-text-field>
</template>
<script>
import { VTextField } from 'vuetify/lib'
import IMask from 'imask'

const VTextFieldMasked = {
  name: 'v-text-field-masked',
  components: { VTextField },
  props: {
    mask: {
      required: true
    },
    value: String
  },
  data: () => ({
    internalValue: '',
    imask: null
  }),
  watch: {
    value (value) {
      // Was modified from outside
      if (value !== this.internalValue) {
        this.updateValue(value)
      }
    }
  },
  computed: {
    inputElement () {
      return this.$refs.textField.$refs.input
    }
  },
  mounted () {
    const initialValue = this.value
    const maskOptions = Object.assign({}, this.mask)
    const imask = this.imask = new IMask(this.inputElement, maskOptions)

    if (initialValue !== this.inputElement.value) {
      this.inputElement.dispatchEvent(new InputEvent('input'))
    }

    // IMask will update the input after the original onInput
    // so calling this manually after IMask is done
    const originalOnInput = this.$refs.textField.onInput
    this.$refs.textField.onInput = () => {}

    imask.on('accept', event => {
      originalOnInput(event)
      this.$nextTick(() => {
        this.internalValue = imask.value
        this.$emit('input', this.internalValue)
      })
    })

    imask.on('complete', this._fireCompleteEvent)

    if (initialValue.length > 0) {
      this.updateValue(initialValue)
    }
  },
  methods: {
    focus () {
      return this.$refs.textField.focus() && this.$refs.textField.focus()
    },
    updateValue (value) {
      this.inputElement.value = value
      // this just fills mask._inputEvent for updateValue()
      this.inputElement.dispatchEvent(new InputEvent('input'))
      this.imask.updateValue()
    },
    onKeyDown (event) {
      if (
        event.target === this.inputElement &&
        event.keyCode === 13 &&
        this.imask.masked.isComplete === true
      ) {
        this._fireCompleteEvent()
      }
    },
    _fireCompleteEvent () {
      this.$nextTick(() => {
        this.$emit('complete', this.internalValue)
      })
    }
  }
}

export function install (Vue) {
  Vue.component(VTextFieldMasked.name, VTextFieldMasked)
}
export default VTextFieldMasked

</script>
<style>
    .v-masked-input .v-input__prepend-outer,
    .v-masked-input .v-input__append-outer,
    .v-masked-input .v-input__slot,

    .v-masked-input .v-messages {
        border: 1px dashed rgba(0,0,0, .4);
    }
</style>
