<template>
    <v-menu
            v-model="showMenu"
            offset-y
            max-width="290px"
            min-width="290px"
    nudge-bottom="12"
    nudge-left="12">
        <template v-slot:activator="{ on }">
            <v-text-field
                    v-model="formattedDate"
                    :label="label"
                    readonly
                    v-on="on"
                    outlined
            ></v-text-field>
        </template>
        <v-date-picker v-model="defaultValue" no-title @input="dateChanged"></v-date-picker>
    </v-menu>
</template>

<script>

import moment from 'moment'

export default {
  components: {
  },
  name: 'DatePicker',
  props: {
    id: {
      type: String,
      default: 'text-field',
      required: true
    },
    label: {
      type: String,
      default: 'text-field',
      required: true
    },
    valueP: {
      type: Date
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY'
    }
  },
  model: {
    prop: 'valueP',
    event: 'change'
  },
  data () {
    return {
      showMenu: false,
      value: this.valueP,
      defaultValue: this.valueP.toISOString().slice(0, 10)
    }
  },
  computed: {
    formattedDate () {
      return moment(this.value).format(this.format)
    }
  },
  methods: {
    dateChanged (value) {
      this.value = new Date(value)
      this.showMenu = false
    }
  },
  watch: {
    value: function (newValue) {
      this.$emit('change', newValue)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
