<template>
   <div class="message">
       <v-alert show type="info">
          <slot>Info message, please fill in the slot</slot>
       </v-alert>
   </div>
</template>

<script>

export default {
  name: 'Info'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
