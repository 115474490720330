<script>
import QStepApi from '@/libraries/Api/QStep/Api'
import Signature from '@/components/CarItinerary/Signature'

export default {
  components: { Signature },
  extends: Signature,
  data () {
    return {
      text: {
        title: 'Driver pick up confirmation',
        alreadySigned: 'Driver has already signed the pick up confirmation',
        signatureSuccess: 'Driver pick up confirmation is signed successfully'
      }
    }
  },
  name: 'DriverSignature',
  mounted () {
    const _self = this
    const qStepApi = new QStepApi()
    this.$showLoadingOverlay()

    let carItineraryPromise = null
    if (this.isPrivate()) {
      carItineraryPromise = qStepApi.getCarItinerary(this.carItineraryId)
    } else if (this.isPublic()) {
      carItineraryPromise = qStepApi.getCarItinerary(this.carItineraryId, this.encryptedData)
    }

    carItineraryPromise.then(response => {
      if (_self.lodash.get(response, 'data.data.consigneeSignature') !== null) {
        _self.isSigned = true
      } else {
        let carItineraryLocationPromise = null
        if (this.isPrivate()) {
          carItineraryLocationPromise = qStepApi.getCarItineraryLocation(this.carItineraryId)
        } else if (this.isPublic()) {
          carItineraryLocationPromise = qStepApi.getCarItineraryLocation(this.carItineraryId, this.encryptedData)
        }

        carItineraryLocationPromise.then(response => {
          if (_self.lodash.get(response, 'data.data.longitude') !== undefined) {
            _self.longitude = response.data.data.longitude
          }

          if (_self.lodash.get(response, 'data.data.latitude') !== undefined) {
            _self.latitude = response.data.data.latitude
          }

          _self.showForm = true
          this.$hideLoadingOverlay()
          _self.doneRendering = true
        }).catch(() => {
          _self.errorMessages.push('Invalid car itinerary given.')
          this.$hideLoadingOverlay()
          _self.doneRendering = true
        })
      }
    }).catch(() => {
      _self.errorMessages.push('Invalid car itinerary given.')
      this.$hideLoadingOverlay()
      _self.doneRendering = true
    })
  },
  methods: {
    sign () {
      if (this.validate()) {
        this.continueWithoutSignature = false
        const _self = this
        const qStepApi = new QStepApi()
        this.$showLoadingOverlay()

        let pickUpPromise = null
        if (this.isPrivate()) {
          pickUpPromise = qStepApi.pickUp(this.$store.getters.consignorSignatureData, this.signatureData(), this.$store.getters.selectedCmrLiabilityIds)
        } else if (this.isPublic()) {
          pickUpPromise = qStepApi.pickUp(this.$store.getters.consignorSignatureData, this.signatureData(), this.$store.getters.selectedCmrLiabilityIds, this.encryptedData)
        }

        pickUpPromise.then(response => {
          _self.showConfirmation()
          this.$hideLoadingOverlay()
          _self.doneRendering = true
        }).catch(() => {
          _self.errorMessages.push('Failed to submit the signature.')
          this.$hideLoadingOverlay()
          _self.doneRendering = true
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
