<template>
    <span class="shc-group-details" v-if="showIcon">
        <img class="dialog-opener" :title="'SHC group: ' + group.name + ', click to view details'" :alt="'SHC group: ' + group.name + ', click to view details'" :src="icon()" @click="updateDetails" />
        <div :id="id" v-if="visible">
            <AlertDialog v-model="visible"
                     :id="id"
                     footer-class="shc-group-details-footer"
                     @hidden="hidden">
                <template v-slot:modal-title>
                    <img :title="'SHC group: ' + group.name" :alt="'SHC group: ' + group.name" :src="icon()" @click="updateDetails" />
                    <span class="title-text">SHC Group {{  group.name }} Details</span>
                </template>
                <slot>
                    <v-container class="shc-details">
                        <v-flex  :key="index" v-for="(shc, index) in shcs">
                           {{ shc.code }} - {{ shc.name }}
                        </v-flex>
                    </v-container>
                </slot>
            </AlertDialog>
        </div>
        <AlertDialog :id="'shc-details-failed' + carId + '-' +  group.id" v-model="showDetailsFailed">Failed to load details for <strong>Shc Group: {{  group.name }}</strong></AlertDialog>
    </span>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import AlertDialog from '@/components/Core/Dialog/Alert'
import QStepApi from '@/libraries/Api/QStep/Api'

export default {
  extends: AccessType,
  components: {
    AlertDialog
  },
  props: {
    carId: {
      type: Number,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  name: 'ShcDetails',
  data () {
    return {
      showDetailsFailed: false,
      visible: false,
      id: 'shc-group-details-' + this.carId + '-' + this.group.id,
      shcs: []
    }
  },
  computed: {
    showIcon () {
      return this.icon() !== null
    }
  },
  methods: {
    hidden () {
      this.$emit('hidden')
    },
    icon () {
      switch (this.group.slug) {
        case 'temperature':
          return require('@/assets/images/shc-group/cold-24x24.jpg')
        case 'room-temperature':
          return require('@/assets/images/shc-group/warm-24x24.jpg')
        case 'adr-imo':
          return require('@/assets/images/shc-group/dangerous-24x24.png')
        case 'temperature-below-minus-ten':
          return require('@/assets/images/shc-group/frozen-24x24.png')
        default:
          return null
      }
    },
    updateDetails () {
      this.$showLoadingOverlay()
      const _self = this
      const qStepApi = new QStepApi()

      let shcDetailsPromise = null

      if (this.isPrivate()) {
        shcDetailsPromise = qStepApi.getShcDetails(this.carId)
      } else if (this.isPublic()) {
        shcDetailsPromise = qStepApi.getShcDetails(this.carId, this.encryptedData)
      }

      shcDetailsPromise.then(response => {
        if (response.data.data !== undefined) {
          const car = response.data.data
          const shcs = []
          if (_self.lodash.get(car, 'shipments') !== undefined) {
            car.shipments.forEach(function (shipment) {
              if (_self.lodash.get(shipment, 'shcs') !== undefined) {
                shipment.shcs.forEach(function (shc) {
                  if (_self.lodash.get(shc, 'groups') !== undefined && _self.lodash.get(shc, 'showInQDrive') !== undefined && _self.lodash.get(shc, 'showInQDrive')) {
                    shc.groups.forEach(function (group) {
                      if (_self.lodash.get(group, 'id') !== undefined && group.id === _self.group.id) {
                        shcs.push({ code: shc.code, name: shc.name })
                      }
                    })
                  }
                })
              }
            })
          }

          _self.shcs = this.lodash.uniqBy(shcs, function (shc) {
            return shc.code
          })

          _self.visible = true
          this.$hideLoadingOverlay()
        }
      }).catch(() => {
        _self.showDetailsFailed = true
        this.$hideLoadingOverlay()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .shc-group-details {
        display: inline-block;

        .v-card__title {
            position: relative;

            img {
                position: absolute;
                top: 15px;
            }

            .title-text {
                text-align: left;
                margin-left: 43px;
            }
        }
    }
</style>
