<template>
  <div class="car-itinerary-item-wrapper">
    <div :class="'car-itinerary-item ' + state">
      <div :id="id">
        <v-card class="mx-auto" @click="showDetails">
          <v-card-title class="title pt-2 pb-2">
            <v-container class="pa-0">
              <v-layout>
                <v-flex>
                  {{ carItineraryHelper.getTitle() }}
                </v-flex>
                <v-flex class="title-icon">
                  <font-awesome-icon v-if="carItineraryHelper.hasConsigneeSignature()" class="fa-lg icon"
                                     icon="file-signature"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
          <v-card-text class="details">
            <v-container>
              <v-flex>
                <span class="name">Client:</span> {{ carItineraryHelper.getClient() }}
              </v-flex>
              <v-flex>
                <span class="name">Truck No.:</span> {{ carItineraryHelper.getTruckNumber() }}
              </v-flex>
              <v-layout>
                <v-flex class="xs3 sm3 md2">
                  <span class="name">Pcs:</span> {{ carItineraryHelper.getPieces() }}
                </v-flex>
                <v-flex class="xs3 sm3 md2">
                  <span class="name">Kg:</span> {{ carItineraryHelper.getKilograms() | thousandFormat }}
                </v-flex>
                <v-flex class="xs3 sm3 md2">
                  <span class="name">Vol:</span> {{ carItineraryHelper.getVolume() }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="icons">
      <router-link v-if="isPrivate()" class="cmr icon"
                   :to="{ name: 'Cmr', params: { carItineraryId: carItineraryHelper.getCarItinerary().id }}">CMR
      </router-link>
      <router-link v-else-if="isPublic()" class="cmr icon"
                   :to="{ name: 'EncryptedCmr', params: { carItineraryId: carItineraryHelper.getCarItinerary().id, encryptedData: encryptedData }}">
        CMR
      </router-link>
      <Notice class="icon" :carItineraryHelper="carItineraryHelper" :accessType="accessType"
              :encryptedData="encryptedData"></Notice>
      <span class="shc-groups icon">
                <ShcDetails :key="group.id" v-for="(group) in carItineraryHelper.getGroups()"
                            :carId="carItineraryHelper.getCarId()" :group="group" :accessType="accessType"
                            :encryptedData="encryptedData"></ShcDetails>
            </span>
    </div>
  </div>
</template>

<script>

import AccessType from '@/components/Core/AccessType'
import ShcDetails from '@/components/CarItinerary/Shc/ShcDetails'
import Notice from '@/components/CarItinerary/Notice'
import Integer from '@/libraries/Util/Integer'

export default {
  extends: AccessType,
  components: {
    ShcDetails, Notice
  },
  props: {
    carItineraryHelper: {
      type: Object,
      required: true
    },
    itemType: {
      type: Boolean
    }
  },
  name: 'Item',
  data () {
    return {
      state: this.carItineraryHelper.getState(),
      detailsVisible: false,
      id: 'car-itinerary-item-' + this.carItineraryHelper.getCarItinerary().id
    }
  },
  filters: {
    thousandFormat (number) {
      return Integer.thousandFormat(number)
    }
  },
  methods: {
    unloadingConfirmed () {
      this.isUnloaded = true
    },
    unloadingCancelled () {
      this.isUnloaded = false
    },
    showDetails () {
      if (this.isPrivate()) {
        this.$router.push({
          name: 'CarItineraryDetails',
          params: { carItineraryId: this.carItineraryHelper.getCarItinerary().id }
        })
      } else if (this.isPublic()) {
        this.$router.push({
          name: 'EncryptedCarItineraryDetails',
          params: { carItineraryId: this.carItineraryHelper.getCarItinerary().id, encryptedData: this.encryptedData }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.car-itinerary-item-wrapper {
  position: relative;

  .loading {
    .title {
      background-image: linear-gradient(to right, Red 49.9%, black 0.1%, #FFCC96 50%) !important;
    }
  }

  .loadingCompleted {
    .title {
      background-color: #FFCC96 !important;
    }
  }

  .unLoading {
    .title {
      background-image: linear-gradient(to right, red 50%, black 0.1%, green 49.9%) !important;
    }
  }

  .unLoadedCompleted {
    .title {
      background-color: green !important;
    }
  }

  .car-itinerary-item {
    width: 100%;

    .title {
      background-color: rgb(191, 203, 217);
      text-align: left;
      font-weight: bold;
      font-size: 16px;

      .text {
        padding: 5px 5px 5px 0px;
        display: inline-block;
      }

      .right-side {
        text-align: right;

        .icon {
          margin-top: 5px;
          margin-right: -10px;
        }
      }

      .title-icon {
        text-align: right;
      }
    }

    .name {
      font-weight: bold;
    }

    .details {
      padding: 5px;
      margin-left: 5px;
      text-align: left;
      font-size: 14px;
    }

    .car-itinerary-notice {
      vertical-align: middle;
      margin-top: -2px;
    }
  }

  .icons {
    bottom: 15px;
    right: 0;
    position: absolute;

    img {
      padding: 4px;
    }

    .cmr {
      font-weight: bold;
      padding: 4px;
    }

    .icon {
      padding: 4px;
    }

    .shc-groups {
      img {
        &.dialog-opener {
          margin-bottom: -9px;
        }
      }

      .headline {
        img {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
